import React, { Component } from "react";
import { VictoryBoxPlot } from "victory";
import {
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryZoomContainer,
  VictoryScatter,
  VictoryLine,
  VictoryBrushContainer,
  VictoryAxis,
} from "victory";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import * as V from "victory";
import { IconButton, MenuItem, TextField } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import AnalytesParameterButton from "../analytes/AnalytesParameterButton";
import Button from "@material-ui/core/Button";
import { FilterList, Settings } from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {
  params
} from "./Data";
class BoxPlot4b extends Component {
  state = {
    param: "FT4",
    date: "1/1/2022",
    labs: ["Demo 1", "DEMOLAB"],
    dataa: ["Median", "Hypo", "Hyper"],
    view: ["Long-term m.", "1.st,3.de q.", "Min,max"],
    groups: ["Alinity", "Cobas"],
  };
  render() {
    const { classes, labs } = this.props;
    return (
      <div>
        <TextField
          // required
          id={"param"}
          value={this.state.param ?? ""}
          name={"param"}
          select
          label={"Analyte"}
          //  InputProps={{ disableUnderline: true }}
          className={classes.selectfilter2}
          // onChange={this.handleChangeParam}
          //  helperText={"Select lab"}
        >
          {params.map((option1) => (
            <MenuItem key={option1} value={option1}>
              {option1}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          // required
          id={"data"}
          value={"Median"}
          name={"data"}
          select
          label={"Measurement"}
          SelectProps={{
            multiple: true,
            value: ["Median", "Hyper"],
          }}
          //  InputProps={{ disableUnderline: true }}
          className={classes.selectfilter2}
          // onChange={this.handleChangeParam}
          //  helperText={"Select lab"}
        >
          {this.state.dataa.map((option1) => (
            <MenuItem key={option1} value={option1}>
              {option1}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          // required
          id={"groupid"}
          value={["Alinity"]}
          name={"groupid"}
          select
          className={classes.selectfilter2}
          label={"Group"}
          SelectProps={{
            multiple: true,
            value: ["Alinity"],
          }}
          // onChange={this.handleChangeLab}
          // InputProps={{ disableUnderline: true }}
          //  helperText={"Select lab"}
        >
          {this.state.groups.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            // required
            // className={classes.datetime}
            className={classes.selectfilter3}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date"
            autoOk={true}
            name="date"
            label="From"
            value={this.state.date}
            // onChange={this.handleChangeDate}
            // helperText={"Select date"}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            // required
            // className={classes.datetime}
            className={classes.selectfilter3}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date"
            autoOk={true}
            name="date"
            label="To"
            value={this.state.date}
            // onChange={this.handleChangeDate}
            // helperText={"Select date"}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>

        <IconButton
          color="primary"
          className={classes.button2}
          aria-label="upload picture"
          component="span"
        >
          <Settings />
        </IconButton>

        <Button
          // variant={labDeviceParameter && labDeviceParameter.includes(labDevice + measure) ? "contained" : "outlined"}
          color="primary"
          variant="outlined"
          // size="small"
          className={classes.button2}
          //  endIcon={<Icon>send</Icon>}
          // onClick={() => this.handleClick(measure)}
        >
          {" "}
          Filter
        </Button>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box>
              <VictoryChart domainPadding={{ x: 20 }}>
              <VictoryLabel text="FT4" x={225} y={30} textAnchor="middle"/>
                <V.VictoryHistogram
                  style={{
                    data: {
                      fill: "#3498db",
                      stroke: "#FFFFFF",
                      strokeWidth: 1,
                    },
                  }}
                  data={[
                    { x: 11.97478111 },
                    { x: 11.0424 },
                    { x: 10.6572 },
                    { x: 11.54930495 },
                    { x: 10.914 },
                    { x: 12.61629608 },
                    { x: 10.272 },
                    { x: 12.47295204 },
                    { x: 10.914 },
                    { x: 12.91193941 },
                    { x: 11.81403952 },
                    { x: 10.7856 },
                    { x: 12.24865391 },
                    { x: 10.7856 },
                    { x: 12.25281702 },
                    { x: 10.4004 },
                    { x: 12.68540933 },
                    { x: 10.272 },
                    { x: 10.0152 },
                    { x: 12.76347898 },
                    { x: 12.45598968 },
                    { x: 10.914 },
                    { x: 11.0424 },
                    { x: 12.59535475 },
                    { x: 10.272 },
                    { x: 12.12143847 },
                    { x: 10.6572 },
                    { x: 11.92964059 },
                    { x: 10.914 },
                    { x: 12.03831077 },
                    { x: 10.914 },
                    { x: 11.55387293 },
                    { x: 12.47798265 },
                    { x: 10.6572 },
                    { x: 11.1708 },
                    { x: 12.48105694 },
                    { x: 11.1708 },
                    { x: 13.14581668 },
                    { x: 12.44267861 },
                    { x: 11.0424 },
                    { x: 10.6572 },
                    { x: 12.68706314 },
                    { x: 11.0424 },
                    { x: 12.45848239 },
                    { x: 12.59973573 },
                    { x: 10.4004 },
                    { x: 11.2992 },
                    { x: 12.8683023 },
                    { x: 10.914 },
                    { x: 12.35264346 },
                    { x: 12.93332679 },
                    { x: 10.7856 },
                    { x: 10.5288 },
                    { x: 11.90442693 },
                    { x: 11.94569084 },
                    { x: 11.0424 },
                    { x: 12.39857143 },
                    { x: 11.4276 },
                    { x: 11.1708 },
                    { x: 12.42885001 },
                    { x: 11.9412 },
                    { x: 11.1708 },
                    { x: 11.6844 },
                    { x: 13.8672 },
                    { x: 12.84 },
                    { x: 11.4276 },
                    { x: 11.556 },
                    { x: 12.198 },
                    { x: 12.3264 },
                    { x: 11.556 },
                    { x: 11.2992 },
                    { x: 11.556 },
                    { x: 11.6844 },
                    { x: 11.4276 },
                    { x: 12.4548 },
                    { x: 12.198 },
                    { x: 11.556 },
                    { x: 12.4548 },
                    { x: 12.5832 },
                    { x: 10.914 },
                    { x: 11.2992 },
                    { x: 12.0696 },
                    { x: 11.1708 },
                    { x: 12.3264 },
                    { x: 11.9412 },
                    { x: 12.3264 },
                    { x: 12.0696 },
                    { x: 11.556 },
                    { x: 13.0968 },
                    { x: 11.8128 },
                    { x: 11.8128 },
                    { x: 11.6844 },
                    { x: 11.556 },
                    { x: 11.8128 },
                    { x: 11.1708 },
                    { x: 11.556 },
                    { x: 12.84 },
                    { x: 11.1708 },
                    { x: 11.6844 },
                    { x: 12.0696 },
                    { x: 11.6844 },
                    { x: 12.5832 },
                    { x: 11.4276 },
                    { x: 12.0696 },
                    { x: 11.8128 },
                    { x: 12.0696 },
                    { x: 11.556 },
                    { x: 12.198 },
                    { x: 11.2992 },
                    { x: 11.8128 },
                    { x: 11.556 },
                    { x: 11.6844 },
                    { x: 11.6844 },
                    { x: 11.9412 },
                    { x: 11.2992 },
                    { x: 11.6844 },
                    { x: 11.2992 },
                    { x: 11.556 },
                    { x: 12.0696 },
                    { x: 11.1708 },
                    { x: 11.4276 },
                    { x: 11.4276 },
                    { x: 11.8128 },
                    { x: 11.2992 },
                    { x: 11.1708 },
                    { x: 11.6844 },
                    { x: 11.6844 },
                    { x: 11.2992 },
                    { x: 11.9412 },
                    { x: 11.8128 },
                    { x: 11.6844 },
                    { x: 11.4276 },
                    { x: 11.6844 },
                    { x: 12.198 },
                    { x: 11.556 },
                    { x: 12.198 },
                    { x: 11.6844 },
                    { x: 11.9412 },
                    { x: 11.6844 },
                    { x: 11.9412 },
                    { x: 11.8128 },
                    { x: 11.6844 },
                    { x: 11.6844 },
                    { x: 11.8128 },
                    { x: 12.198 },
                    { x: 11.9412 },
                    { x: 12.0696 },
                    { x: 11.9412 },
                    { x: 11.8128 },
                    { x: 11.6844 },
                    { x: 11.8128 },
                    { x: 11.4276 },
                    { x: 11.4276 },
                    { x: 11.4276 },
                    { x: 11.2992 },
                    { x: 12.0696 },
                    { x: 11.8128 },
                    { x: 11.6844 },
                    { x: 12.198 },
                    { x: 11.8128 },
                    { x: 11.556 },
                    { x: 11.2992 },
                    { x: 11.0424 },
                    { x: 12.198 },
                    { x: 12.7116 },
                    { x: 10.1436 },
                    { x: 10.4004 },
                    { x: 12.0696 },
                    { x: 11.6844 },
                    { x: 10.0152 },
                    { x: 10.4004 },
                    { x: 12.5832 },
                    { x: 12.3264 },
                    { x: 9.8868 },
                    { x: 9.8868 },
                    { x: 12.0696 },
                    { x: 13.9956 },
                    { x: 14.5092 },
                    { x: 9.7584 },
                    { x: 12.0696 },
                    { x: 10.0152 },
                    { x: 12.7116 },
                    { x: 11.556 },
                    { x: 10.0152 },
                    { x: 12.83485212 },
                    { x: 10.272 },
                    { x: 11.8128 },
                    { x: 11.21007703 },
                    { x: 12.39178342 },
                    { x: 11.8128 },
                    { x: 12.18326106 },
                    { x: 11.9412 },
                    { x: 11.35347997 },
                    { x: 12.198 },
                    { x: 12.198 },
                    { x: 12.08854929 },
                    { x: 12.5832 },
                    { x: 11.99624731 },
                    { x: 11.8128 },
                    { x: 11.47138161 },
                    { x: 12.3264 },
                    { x: 11.1004021 },
                    { x: 12.27489568 },
                    { x: 11.9412 },
                    { x: 11.6844 },
                    { x: 11.77532199 },
                    { x: 11.8128 },
                    { x: 11.05150392 },
                    { x: 11.8128 },
                    { x: 11.56704609 },
                    { x: 11.9412 },
                    { x: 12.06190542 },
                    { x: 12.0696 },
                    { x: 12.19042068 },
                    { x: 12.32310363 },
                    { x: 11.9412 },
                    { x: 12.41179377 },
                    { x: 11.4276 },
                    { x: 11.92463905 },
                    { x: 12.0696 },
                    { x: 11.9412 },
                    { x: 11.90046731 },
                    { x: 12.4616012 },
                    { x: 12.5832 },
                    { x: 12.58157295 },
                    { x: 11.2992 },
                    { x: 11.6844 },
                    { x: 11.18205842 },
                    { x: 10.5288 },
                    { x: 12.16338223 },
                    { x: 12.4548 },
                    { x: 12.34031246 },
                    { x: 11.57211273 },
                    { x: 12.0696 },
                    { x: 11.84031846 },
                    { x: 12.198 },
                    { x: 11.20734357 },
                    { x: 12.5832 },
                    { x: 11.2226384 },
                    { x: 11.6844 },
                    { x: 11.50936765 },
                    { x: 11.8128 },
                    { x: 12.198 },
                    { x: 11.35369842 },
                    { x: 12.2280923 },
                    { x: 11.65156284 },
                    { x: 12.17716746 },
                    { x: 12.38881663 },
                    { x: 12.20005301 },
                    { x: 13.28147213 },
                    { x: 12.20005301 },
                    { x: 11.85702472 },
                    { x: 10.90930188 },
                    { x: 12.33855582 },
                    { x: 11.59631935 },
                    { x: 12.32255588 },
                    { x: 12.7565744 },
                    { x: 11.17690944 },
                    { x: 11.59598653 },
                    { x: 12.94756826 },
                    { x: 12.07878486 },
                    { x: 12.45144972 },
                    { x: 11.53748814 },
                    { x: 12.45031507 },
                    { x: 11.30263749 },
                    { x: 12.76299634 },
                    { x: 12.45975868 },
                    { x: 11.95054932 },
                    { x: 12.66575459 },
                    { x: 12.89997163 },
                    { x: 12.40479426 },
                    { x: 12.89997163 },
                    { x: 10.95571029 },
                    { x: 11.08358499 },
                    { x: 11.83482531 },
                    { x: 12.43048885 },
                    { x: 11.30411402 },
                    { x: 12.08393222 },
                    { x: 11.34815152 },
                    { x: 12.09449543 },
                    { x: 11.72463104 },
                    { x: 12.72818409 },
                    { x: 11.25764622 },
                    { x: 13.3294073 },
                    { x: 12.02764901 },
                    { x: 12.87184322 },
                    { x: 12.54053664 },
                    { x: 11.79710575 },
                    { x: 11.72409007 },
                    { x: 12.31812172 },
                    { x: 11.77909223 },
                    { x: 12.03390634 },
                    { x: 12.39816198 },
                    { x: 12.55116285 },
                    { x: 12.60817002 },
                    { x: 11.7894794 },
                    { x: 12.22828444 },
                    { x: 12.27381373 },
                    { x: 11.85712422 },
                    { x: 11.50024885 },
                    { x: 12.41989124 },
                    { x: 13.29765334 },
                    { x: 12.15075348 },
                    { x: 12.48115978 },
                    { x: 11.56916596 },
                    { x: 12.17712858 },
                    { x: 12.24028415 },
                    { x: 12.05717283 },
                    { x: 11.90905552 },
                    { x: 11.65608735 },
                    { x: 11.51510622 },
                    { x: 11.90868267 },
                    { x: 12.89419694 },
                    { x: 11.59805903 },
                    { x: 11.29909084 },
                    { x: 12.85419578 },
                    { x: 12.58823401 },
                    { x: 10.80457463 },
                    { x: 13.32680422 },
                    { x: 12.61075586 },
                    { x: 11.66485389 },
                    { x: 11.09684411 },
                    { x: 11.93714724 },
                    { x: 12.72461228 },
                    { x: 12.19915748 },
                    { x: 12.9683998 },
                    { x: 11.38015815 },
                    { x: 12.11680416 },
                    { x: 11.34195938 },
                    { x: 12.03242123 },
                    { x: 11.56050064 },
                    { x: 12.45273239 },
                    { x: 12.74943651 },
                    { x: 11.94500224 },
                    { x: 12.0927365 },
                    { x: 11.31093167 },
                    { x: 12.98372323 },
                    { x: 12.59114466 },
                    { x: 12.01012623 },
                    { x: 12.19730019 },
                    { x: 12.12845228 },
                    { x: 11.33789921 },
                    { x: 12.64277974 },
                    { x: 12.48776709 },
                    { x: 11.98070552 },
                    { x: 12.60058141 },
                    { x: 12.43616575 },
                    { x: 11.6081191 },
                    { x: 11.67005892 },
                    { x: 11.91709686 },
                    { x: 12.46538868 },
                    { x: 12.5962113 },
                    { x: 11.66193409 },
                    { x: 12.07957468 },
                    { x: 12.10358335 },
                    { x: 11.17225577 },
                    { x: 11.53971142 },
                    { x: 12.4442066 },
                    { x: 11.80645892 },
                    { x: 11.62539818 },
                    { x: 11.86517365 },
                    { x: 11.53118049 },
                    { x: 11.04674961 },
                    { x: 11.88255118 },
                    { x: 11.89684633 },
                    { x: 11.8269136 },
                    { x: 13.07596115 },
                    { x: 12.36620549 },
                    { x: 12.52553354 },
                    { x: 11.15536652 },
                    { x: 12.46512849 },
                    { x: 12.45427411 },
                    { x: 11.72159335 },
                    { x: 11.6599326 },
                    { x: 12.23233308 },
                    { x: 11.18137677 },
                    { x: 12.50850827 },
                    { x: 11.80403377 },
                    { x: 12.01791261 },
                    { x: 12.28552713 },
                    { x: 11.88778757 },
                    { x: 13.8211889 },
                    { x: 11.28706473 },
                    { x: 12.33226475 },
                    { x: 11.65396062 },
                    { x: 11.80576707 },
                    { x: 12.18855987 },
                    { x: 11.78451808 },
                    { x: 11.75500055 },
                    { x: 12.10138237 },
                    { x: 11.98676833 },
                    { x: 12.21510652 },
                    { x: 12.52454528 },
                    { x: 11.86041468 },
                    { x: 11.32525673 },
                    { x: 11.81560631 },
                    { x: 12.27212399 },
                    { x: 12.16757851 },
                    { x: 11.58397643 },
                    { x: 12.18893738 },
                    { x: 12.04106139 },
                    { x: 11.93006481 },
                    { x: 11.67887815 },
                    { x: 12.62294094 },
                    { x: 12.04327895 },
                    { x: 12.20022694 },
                    { x: 12.21574528 },
                    { x: 11.89740798 },
                    { x: 11.13777978 },
                    { x: 12.21437741 },
                    { x: 11.57431552 },
                    { x: 12.14558162 },
                    { x: 12.83227085 },
                    { x: 11.54329753 },
                    { x: 12.24339276 },
                    { x: 12.20873557 },
                  ]}
                  // bins={[0, 3, 7, 10]}
                  bins={20}
                />
                <VictoryAxis
                  crossAxis
                  label="pmol/L"
                  style={{
                    axisLabel: { fontSize: 8, padding: 25 },
                    tickLabels: { fontSize: 6, padding: 5 },
                  }}
                />
                <VictoryAxis
                  dependentAxis
                  crossAxis
                  label="Frequency"
                  style={{
                    axisLabel: { fontSize: 8, padding: 25 },
                    tickLabels: { fontSize: 6, padding: 5 },
                  }}
                />
              </VictoryChart>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <VictoryChart domainPadding={{ x: 20 }}>
              <VictoryLabel text="FT4(Hyper)" x={225} y={30} textAnchor="middle"/>
                <V.VictoryHistogram
                  style={{
                    data: {
                      fill: "#c43a31",
                      stroke: "#FFFFFF",
                      strokeWidth: 1,
                    },
                  }}
                  data={[
                    { x: 3.0 },
                    { x: 2.0 },
                    { x: 4.0 },
                    { x: 5.0 },
                    { x: 8.0 },
                    { x: 6.0 },
                    { x: 6.0 },
                    { x: 7.0 },
                    { x: 5.0 },
                    { x: 5.0 },
                    { x: 5.0 },
                    { x: 5.0 },
                    { x: 6.0 },
                    { x: 4.0 },
                    { x: 2.0 },
                    { x: 3.0 },
                    { x: 25.0 },
                    { x: 7.0 },
                    { x: 7.0 },
                    { x: 9.0 },
                    { x: 3.0 },
                    { x: 5.0 },
                    { x: 13.0 },
                    { x: 11.0 },
                    { x: 5.0 },
                    { x: 4.0 },
                    { x: 17.0 },
                    { x: 8.0 },
                    { x: 11.0 },
                    { x: 13.0 },
                    { x: 5.0 },
                    { x: 4.0 },
                    { x: 4.0 },
                    { x: 3.0 },
                    { x: 6.0 },
                    { x: 9.0 },
                    { x: 2.0 },
                    { x: 4.0 },
                    { x: 5.0 },
                    { x: 9.0 },
                    { x: 5.0 },
                    { x: 15.0 },
                    { x: 11.0 },
                    { x: 4.0 },
                    { x: 17.0 },
                    { x: 8.0 },
                    { x: 9.0 },
                    { x: 6.0 },
                    { x: 2.0 },
                    { x: 5.0 },
                    { x: 8.0 },
                    { x: 9.0 },
                    { x: 2.0 },
                    { x: 5.0 },
                    { x: 2.0 },
                    { x: 3.0 },
                    { x: 6.0 },
                    { x: 14.0 },
                    { x: 5.0 },
                    { x: 6.0 },
                    { x: 5.0 },
                    { x: 5.0 },
                    { x: 3.0 },
                    { x: 13.0 },
                    { x: 5.0 },
                    { x: 15.0 },
                    { x: 4.0 },
                    { x: 9.0 },
                    { x: 12.0 },
                    { x: 13.0 },
                    { x: 12.0 },
                    { x: 4.0 },
                    { x: 5.0 },
                    { x: 2.0 },
                    { x: 4.0 },
                    { x: 5.0 },
                    { x: 6.0 },
                    { x: 15.0 },
                    { x: 5.0 },
                    { x: 2.0 },
                    { x: 14.0 },
                    { x: 5.0 },
                    { x: 4.0 },
                    { x: 4.0 },
                    { x: 12.0 },
                    { x: 4.0 },
                    { x: 7.0 },
                    { x: 5.0 },
                    { x: 9.0 },
                    { x: 3.0 },
                    { x: 3.0 },
                    { x: 4.0 },
                    { x: 3.0 },
                    { x: 3.0 },
                    { x: 4.0 },
                    { x: 3.0 },
                    { x: 9.0 },
                    { x: 3.0 },
                    { x: 8.0 },
                    { x: 6.0 },
                    { x: 10.0 },
                    { x: 10.0 },
                    { x: 10.0 },
                    { x: 13.0 },
                    { x: 12.0 },
                    { x: 9.0 },
                    { x: 13.0 },
                    { x: 4.0 },
                    { x: 2.0 },
                    { x: 9.0 },
                    { x: 6.0 },
                    { x: 6.0 },
                    { x: 11.0 },
                    { x: 2.0 },
                    { x: 3.0 },
                    { x: 15.0 },
                    { x: 8.0 },
                    { x: 5.0 },
                    { x: 5.0 },
                    { x: 3.0 },
                    { x: 11.0 },
                    { x: 6.0 },
                    { x: 4.0 },
                    { x: 5.0 },
                    { x: 4.0 },
                    { x: 12.0 },
                    { x: 3.0 },
                    { x: 10.0 },
                    { x: 7.0 },
                    { x: 13.0 },
                    { x: 3.0 },
                    { x: 2.0 },
                    { x: 4.0 },
                    { x: 12.0 },
                    { x: 10.0 },
                    { x: 7.0 },
                    { x: 12.0 },
                    { x: 9.0 },
                    { x: 7.0 },
                    { x: 6.0 },
                    { x: 2.0 },
                    { x: 8.0 },
                    { x: 3.0 },
                    { x: 3.0 },
                    { x: 8.0 },
                    { x: 8.0 },
                    { x: 9.0 },
                    { x: 3.0 },
                    { x: 5.0 },
                    { x: 10.0 },
                    { x: 5.0 },
                    { x: 5.0 },
                    { x: 4.0 },
                    { x: 3.0 },
                    { x: 14.0 },
                    { x: 8.0 },
                    { x: 7.0 },
                    { x: 11.0 },
                    { x: 5.0 },
                    { x: 2.0 },
                    { x: 8.0 },
                    { x: 7.0 },
                    { x: 20.0 },
                    { x: 4.0 },
                    { x: 8.0 },
                    { x: 10.0 },
                    { x: 6.0 },
                    { x: 3.0 },
                    { x: 5.0 },
                    { x: 7.0 },
                    { x: 7.0 },
                    { x: 5.0 },
                    { x: 3.0 },
                    { x: 1.0 },
                    { x: 13.0 },
                    { x: 12.0 },
                    { x: 8.0 },
                    { x: 8.0 },
                    { x: 14.0 },
                    { x: 2.0 },
                    { x: 2.0 },
                    { x: 1.0 },
                    { x: 10.0 },
                    { x: 2.0 },
                    { x: 8.0 },
                    { x: 3.0 },
                    { x: 8.0 },
                    { x: 4.0 },
                    { x: 14.0 },
                    { x: 8.0 },
                    { x: 11.0 },
                    { x: 10.0 },
                    { x: 7.0 },
                    { x: 7.0 },
                    { x: 5.0 },
                    { x: 5.0 },
                    { x: 6.0 },
                    { x: 8.0 },
                    { x: 7.0 },
                    { x: 4.0 },
                    { x: 12.3 },
                    { x: 6.0 },
                    { x: 12.3 },
                    { x: 11.6 },
                    { x: 8.2 },
                    { x: 10.0 },
                    { x: 6.7 },
                    { x: 11.0 },
                    { x: 7.0 },
                    { x: 4.0 },
                    { x: 9.0 },
                    { x: 15.1 },
                    { x: 3.0 },
                    { x: 11.5 },
                    { x: 4.0 },
                    { x: 1.0 },
                    { x: 10.1 },
                    { x: 8.8 },
                    { x: 3.0 },
                    { x: 11.5 },
                    { x: 13.0 },
                    { x: 9.2 },
                    { x: 10.2 },
                    { x: 2.0 },
                    { x: 9.0 },
                    { x: 5.2 },
                    { x: 8.0 },
                    { x: 9.3 },
                    { x: 7.0 },
                    { x: 8.0 },
                    { x: 5.5 },
                    { x: 6.3 },
                    { x: 12.3 },
                    { x: 2.0 },
                    { x: 3.0 },
                    { x: 7.0 },
                    { x: 11.6 },
                    { x: 10.0 },
                    { x: 9.2 },
                    { x: 10.7 },
                    { x: 4.0 },
                    { x: 9.0 },
                    { x: 9.9 },
                    { x: 13.9 },
                    { x: 5.0 },
                    { x: 14.4 },
                    { x: 2.0 },
                    { x: 7.0 },
                    { x: 10.0 },
                    { x: 2.0 },
                    { x: 15.1 },
                    { x: 2.0 },
                    { x: 7.4 },
                    { x: 2.0 },
                    { x: 7.4 },
                    { x: 10.5 },
                    { x: 7.0 },
                    { x: 3.9 },
                    { x: 8.0 },
                    { x: 5.0 },
                    { x: 9.0 },
                    { x: 12.0 },
                    { x: 7.8 },
                    { x: 11.1 },
                    { x: 2.0 },
                    { x: 12.6 },
                    { x: 6.0 },
                    { x: 7.0 },
                    { x: 7.0 },
                    { x: 7.2 },
                    { x: 16.5 },
                    { x: 6.0 },
                    { x: 9.0 },
                    { x: 13.4 },
                    { x: 5.0 },
                    { x: 14.2 },
                    { x: 9.4 },
                    { x: 10.0 },
                    { x: 3.0 },
                    { x: 10.8 },
                    { x: 9.7 },
                    { x: 9.1 },
                    { x: 4.0 },
                    { x: 6.0 },
                    { x: 10.9 },
                    { x: 1.0 },
                    { x: 11.0 },
                    { x: 9.0 },
                    { x: 9.4 },
                    { x: 13.2 },
                    { x: 7.0 },
                    { x: 12.2 },
                    { x: 2.0 },
                    { x: 8.1 },
                    { x: 7.0 },
                    { x: 10.4 },
                    { x: 3.0 },
                    { x: 11.7 },
                    { x: 7.0 },
                    { x: 4.5 },
                    { x: 3.0 },
                    { x: 13.6 },
                    { x: 2.0 },
                    { x: 7.0 },
                    { x: 8.9 },
                    { x: 12.9 },
                    { x: 10.1 },
                    { x: 5.0 },
                    { x: 6.0 },
                    { x: 11.3 },
                    { x: 11.4 },
                    { x: 4.0 },
                    { x: 3.7 },
                    { x: 17.0 },
                    { x: 9.8 },
                    { x: 2.0 },
                    { x: 14.0 },
                    { x: 10.0 },
                    { x: 10.4 },
                    { x: 7.0 },
                    { x: 10.3 },
                    { x: 9.0 },
                    { x: 10.1 },
                    { x: 10.0 },
                    { x: 6.0 },
                    { x: 7.0 },
                    { x: 11.8 },
                    { x: 2.0 },
                    { x: 8.9 },
                    { x: 17.8 },
                    { x: 4.0 },
                    { x: 10.7 },
                    { x: 5.0 },
                    { x: 3.0 },
                    { x: 21.1 },
                    { x: 15.1 },
                    { x: 17.3 },
                    { x: 10.0 },
                    { x: 15.3 },
                    { x: 26.5 },
                    { x: 4.0 },
                    { x: 22.9 },
                    { x: 2.0 },
                    { x: 15.0 },
                    { x: 13.4 },
                    { x: 8.0 },
                    { x: 7.0 },
                    { x: 13.6 },
                    { x: 7.0 },
                    { x: 16.3 },
                    { x: 13.0 },
                    { x: 7.0 },
                    { x: 6.0 },
                    { x: 11.0 },
                    { x: 7.0 },
                    { x: 18.5 },
                    { x: 2.0 },
                    { x: 11.0 },
                    { x: 16.0 },
                    { x: 14.6 },
                    { x: 9.0 },
                    { x: 12.9 },
                    { x: 10.0 },
                    { x: 15.6 },
                    { x: 8.0 },
                    { x: 10.0 },
                    { x: 22.1 },
                    { x: 6.0 },
                    { x: 20.8 },
                    { x: 22.3 },
                    { x: 8.0 },
                    { x: 16.0 },
                    { x: 17.0 },
                    { x: 8.0 },
                    { x: 7.0 },
                    { x: 8.0 },
                    { x: 8.0 },
                    { x: 13.3 },
                    { x: 22.4 },
                    { x: 21.5 },
                  ]}
                  // bins={[0, 3, 7, 10]}
                  bins={20}
                />
                <VictoryAxis
                  crossAxis
                  label="% high values"
                  style={{
                    axisLabel: { fontSize: 8, padding: 25 },
                    tickLabels: { fontSize: 6, padding: 5 },
                  }}
                />
                <VictoryAxis
                  dependentAxis
                  crossAxis
                  label="Frequency"
                  style={{
                    axisLabel: { fontSize: 8, padding: 25 },
                    tickLabels: { fontSize: 6, padding: 5 },
                  }}
                />
              </VictoryChart>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default BoxPlot4b;
