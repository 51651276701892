
import React, { Component } from 'react';
import Chart from './Chart';
import { getData } from "./utils"
import Button from '@material-ui/core/Button';

import { TypeChooser } from "react-stockcharts/lib/helper";

// const average = list => list.reduce((prev, { hyper }) => prev + parseFloat(hyper), 0);
const average_hypo = list => list.reduce((prev, curr) => prev + curr.hypo, 0) / list.length;
const average_hyper = list => list.reduce((prev, curr) => prev + curr.hyper, 0) / list.length;
const average_result = list => list.reduce((prev, curr) => prev + parseFloat(curr.result), 0) / list.length;

class StatisticsChartStock extends Component {
    componentDidMount() {
        // demo data
        // getData().then(data => {
        // })
        // const data = this.props.results.map((e) => (
        //     {
        //         date: new Date(e.date),
        //         open: parseFloat(e.result),
        //         high: parseFloat(e.result) + 2,
        //         low: parseFloat(e.result) - 1,
        //         close: parseFloat(e.result) + 1,
        //         volume: 0,
        //         split: "",
        //         dividend: "",
        //         absoluteChange: "",
        //         percentChange: "",
        //         smaVolume50: 0,
        //         macd: {
        //             macd: 0.0360574117722372,
        //             signal: -0.09202447114314383,
        //             divergence: 0.12808188291538103
        //         },
        //         bb: {
        //             "top": 47.93055713979569,
        //             "middle": 45.4325,
        //             "bottom": 42.93444286020431
        //         },
        //         ema12: 24.181185010078675,
        //         ema26: 24.145127598306438
        //     }
        // )
        // );
        // this.setState({ data });
    }
    render() {
        const { classes } = this.props;
        let data = null;
        console.log('average................', this.props.results);
        if (this.props.results && this.props.results.length > 0) {

            // console.log('average................', average(Object.values(this.props.results)));
            let limit = this.props.parameters[this.props.form.param].limit;
            let ave_hyper = average_hyper(this.props.results);
            let ave_hypo = average_hypo(this.props.results);
            let ave_hyper_max = ave_hyper * (1 + limit / 100);
            let ave_hyper_min = ave_hyper * (1 - limit / 100);
            let ave_hypo_max = ave_hyper * (1 + limit / 100);
            let ave_hypo_min = ave_hyper * (1 - limit / 100);

            console.log('limit................', limit);
            console.log('average2................', ave_hypo);
            console.log('average2................', ave_hyper);
            // console.log('average2................',average_result(this.props.results) );

            data = this.props.results.map((e, index, arr) => {
                // let hyper = parseFloat(e.hyper);
                let hyper = typeof e.hyper != 'undefined' ? parseFloat(e.hyper) : Math.floor(Math.random() * 20);
                let hypo = typeof e.hypo != 'undefined' ? parseFloat(e.hypo) : Math.floor(Math.random() * 20);
                let close = parseFloat(e.result);
                let last = index === 0 ? close : parseFloat(arr[index - 1].result);
                let open = index === 0 ? close : last;
                let high = open < close ? close + (close - open) / 2 : open + (open - close) / 2;
                let low = open >= close ? close + (close - open) / 2 : open + (open - close) / 2;
                high = open < close ? (
                    hyper === null ?
                        close :
                        close + hyper
                ) : (
                    hypo === null ?
                        open :
                        open + hyper
                );
                low = open >= close ? (
                    hypo === null ?
                        close :
                        close - hypo
                ) : (
                    hypo === null ?
                        open :
                        open - hypo
                );
                let sum = typeof e.all != 'undefined' ? parseFloat(e.all) : Math.floor(Math.random() * 200);

                if (this.props.form.distribution === '100') {
                    hyper = hyper;
                    hypo = hypo;
                    sum = 100;

                }
                return (
                    {
                        date: new Date(e.date),
                        open: open,
                        high: hyper,
                        low: hypo,
                        close: close,
                        volume: sum,
                        split: "",
                        dividend: "",
                        absoluteChange: "",
                        percentChange: "",
                        bb: {
                            top: this.props.parameters[this.props.form.param].target + this.props.parameters[this.props.form.param].bias,
                            middle: this.props.parameters[this.props.form.param].target,
                            bottom: this.props.parameters[this.props.form.param].target - this.props.parameters[this.props.form.param].bias
                        },
                        limit: {
                            top: this.props.form.distribution === '100' ? 100 - ave_hyper_max : sum - ave_hyper_max * sum / 100,
                            bottom: this.props.form.distribution === '100' ? 100 - ave_hyper_min : sum - ave_hyper_min * sum / 100,
                            ave_hyper_max: ave_hyper_max,
                            ave_hyper_min: ave_hyper_min,
                            ave_hyper: ave_hyper,
                            high: this.props.form.distribution === '100' ? 100- hyper : sum-(hyper * sum) / 100,
                            // middle: 50,

                        },
                        limit_hypo: {
                            bottom: this.props.form.distribution === '100' ? ave_hypo_max : ave_hypo_max * sum / 100,
                            top: this.props.form.distribution === '100' ? ave_hypo_min : ave_hypo_min * sum / 100,
                            ave_hypo_min: ave_hypo_min,
                            ave_hypo_max: ave_hypo_max,
                            ave_hypo:ave_hypo,
                            low: this.props.form.distribution === '100' ? hypo : (hypo * sum) / 100,
                            // middle: 50,
                            
                        }
                        // bb: {
                        //     top: high,
                        //     middle: (high+low)/2,
                        //     bottom: low
                        // },

                        // smaVolume50: 0,
                        // macd: {
                        //     macd: 0.0360574117722372,
                        //     signal: -0.09202447114314383,
                        //     divergence: 0.12808188291538103
                        // },
                        // ema12: 24.181185010078675,
                        // ema26: 24.145127598306438
                    }
                )
            }
            );
        }
        console.log('data', data);
        if (data == null || data.length < 1) {
            return <div>Loading...</div>
        }
        if (data.length > 0) {
            return (
                <>
                    <Chart width={1400} type={"hybrid"} form={this.props.form} data={data} />
                    <Button
                        variant='contained'
                        color='primary'
                        className={classes.button}
                        onClick={() => {
                            let data = this.props.results.map((e, index, arr) => {
                                let hyper = typeof e.hyper != 'undefined' ? parseFloat(e.hyper) : Math.floor(Math.random() * 20);
                                let hypo = typeof e.hypo != 'undefined' ? parseFloat(e.hypo) : Math.floor(Math.random() * 20);
                                let sum = Math.floor(Math.random() * 200);
                                return (
                                    {
                                        ...e,
                                        hyper: hyper,
                                        hypo: hypo,
                                        all: sum,
                                    }
                                )
                            }
                            );
                            this.props.importAdd(data, this.props.id);
                        }}
                    >
                        Save
                    </Button>
                </>
            )
        }

    }
}

export default StatisticsChartStock;