import React, { Component } from "react";
import { readString } from 'react-papaparse';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress';
class ImportHypo extends Component {
    constructor(props) {
        super();
    }

    state = {
        task: "",
        checked: "false",
        counter: 0,
        max: 0,
        data: {},
        element: null,
        firstLine: '',
        timer: null,
        open: false
    };
    val = '"date";"day";"result";"unit";"parameter";"device";"device-model";"device-vendor";"lab";"device-id"';
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    };

    importElement = (element, firstLine) => {
        let weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        let data = {
            date: element[firstLine.indexOf('date')] ? element[firstLine.indexOf('date')] : "",
            lab: element[firstLine.indexOf('lab')] ? element[firstLine.indexOf('lab')] : "",
            day: element[firstLine.indexOf('day')] ? element[firstLine.indexOf('day')] : "",
            result: element[firstLine.indexOf('result')] ? element[firstLine.indexOf('result')] : "",
            unit: element[firstLine.indexOf('unit')] ? element[firstLine.indexOf('unit')] : "",
            parameter: element[firstLine.indexOf('parameter')] ? element[firstLine.indexOf('parameter')] : "",
            device: element[firstLine.indexOf('device')] ? element[firstLine.indexOf('device')] : "",
        }
        let d = this.props.measurements.filter(
            v => v.parameter === data.parameter
                && v.lab_name === data.lab
                && v.device_name === data.device
        )[0]
        if (d) {
            let r = d.results.filter(
                v => v.date === data.date)[0]
            let ii = d.results.indexOf(r);
            let a = d.results;
            if (ii < 0) {
                a = d.results.concat({
                    date: data.date,
                    result: data.result,
                    day: weekday[new Date(data.date).getDay()],
                    unit: data.unit
                });
            } else {
                a = [...d.results];
                a[ii] = { ...a[ii], hypo: data.result };
                this.props.importAdd(a, d.id);

            }
        } else {
            let l = this.props.labs.filter(v => v.name === data.lab)[0]
            let d = l.devices.filter(v => v.name === data.device)[0]
            let newMeasure = {
                device: d.id,
                lab: l.id,
                lab_name: l.name,
                device_name: d.name,
                parameter: data.parameter,
                unit: data.unit,
                name: l.name + '-' + d.name + '-' + data.parameter,
                results: [
                    {
                        date: data.date,
                        result: data.result,
                        day: weekday[new Date(data.date).getDay()],
                        unit: data.unit
                    }
                ]
            }
        }

    }

    incrementCounter() {
        const { counter, data, firstLine } = this.state;
        if (this.props.state.loading) {
            // this.setState({ counter: 0 });
        } else {
            let element = data[counter];
            this.importElement(element, firstLine);
            this.setState({ counter: counter + 1 });
        }

        if (counter === data.length - 1) {
            this.setState({ counter: 0 });
            this.setState({ open: false });
            clearInterval(this.state.timer);
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ open: true });
        let data = {};
        const results = readString(this.state.task, { delimiter: ";" });
        const firstLine = results.data.shift();
        const thisBoundedIncrementer = this.incrementCounter.bind(this);
        let timer = setInterval(thisBoundedIncrementer, 250);
        this.setState({ timer: timer, data: results.data, firstLine: firstLine });
    };

    render() {
        const { classes } = this.props;
        return (
            <>

                <form
                    id="addTaskForm"
                    className="container"
                    autoComplete="off"
                    style={{ marginTop: "30px" }}
                    onSubmit={this.handleSubmit}
                >
                    <legend> </legend>
                    <div className="form-group">
                        <h1 htmlFor="task">Import Hypo CSV data</h1>

                        <TextField
                            rowsMin={30}
                            id="task"
                            aria-label="maximum height"
                            placeholder="Maximum 4 rows"
                            defaultValue={this.val}
                            onChange={this.handleChange}
                            inputProps={{ className: classes.textarea }}
                            multiline
                            // classes={{ paper: classes.textarea }}
                            variant="outlined"
                            rows={10}
                        />
                    </div>
                    <Button
                        type='submit'
                        variant="contained"
                        color="primary"
                        className={classes.button}
                    >
                        Import
                    </Button>
                </form>

                <Dialog classes={{ paper: classes.dialog }}
                    open={this.state.open}
                    // onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Import"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.counter}/{this.state.data.length}
                            {this.state.element && <div>
                                <div>Date: {this.state.element.date}</div>
                                <div>Lab: {this.state.element.lab}</div>
                                <div>Device: {this.state.element.device}</div>
                                <div>Parameter: {this.state.element.parameter}</div>
                                <div>Result: {this.state.element.result}</div>
                            </div>
                            }
                            <LinearProgress variant="determinate" value={this.state.data.length > 0 ? 100 * (this.state.counter / this.state.data.length) : 0} />
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

export default ImportHypo;
