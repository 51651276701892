import React, { Component } from 'react';
import StatisticsFilters from './StatisticsFilters';
import StatisticsChart from './StatisticsChart';
import StatisticsTable from './StatisticsTable';
import StatisticsFiltersChart from './StatisticsFiltersChart';
import StatisticsChartVictory from './StatisticsChartVictory';
import StatisticsChartVictory2 from './StatisticsChartVictory2';
import StatisticsChartReactive from './StatisticsChartReactive';
import StatisticsChartStock from './StatisticsChartStock';

class Statistics extends Component {
    constructor(props) {
        super();
        this.handleChangeForm = this.handleChangeForm.bind(this);
        this.handleChangeChart = this.handleChangeChart.bind(this);

        this.state = {
            params: ['Albumin', 'Alkaline Phosphatase', 'ALT - GPT', 'AST - GOT', 'Calcium', 'Chloride', 'Creatinine', 'FT4', 'GGT', 'Glucose', 'Inorganic phosphor', 'LDH', 'Magnesium', 'Potassium', 'Sodium', 'total-Bilirubin', 'total-Cholesterol', 'total-Protein', 'TSH', 'Urea', 'Uric acid'],
            units: ['g/L', 'mg/L', 'mIU/L', 'mmol/L', 'pmol/L', 'U/L', 'µmol/L'],
            data: [],
            page: 0,
            rowsPerPage: 10,
            form: {},
            charts: ['Rechart', 'VictoryBar', 'VictoryBar Zoom', 'Reactive', 'Stock', 'Stock2'],
            chart: 'Stock'
        }
    }
    componentDidMount() {
        if (this.props.labs && this.props.labs[1] && this.state.form.param === undefined) {
            console.log('componentDidMount', this.state);
            this.setState({
                form: {
                    param: 'FT4',
                    date: new Date('2015-01-01'),
                    toDate: new Date('2015-12-31'),
                    lab: this.props.labs[1],
                    device: this.props.labs[1].devices[0],
                    ema12: false,
                    ema26: false,
                    macd: false,
                    distribution: '100',
                    range: true,
                    ypan: true
                },
            });
        }
    }

    handleChangeForm = (f) => {
        this.setState({ form: f });
    }

    handleChangeChart = (f) => {
        this.setState({ chart: f });
    }
    // handleChangeYpan = (f) => {
    //     this.setState({ yPan: !this.state.yPan });
    //     console.log('yPan', this.state);
    // }

    render() {

        const { classes, labs, parameters, form, searchForTheNewMeasurement, handleChangeForm, submitForm, measurements } = this.props;
        const data = this.props.measurements.filter(
            v => v.parameter === this.state.form.param
                && v.lab_name === this.state.form.lab.name
                && v.device_name === this.state.form.device.name
        )[0]
        const results = data && data.results ? data.results : [];
        const resultsRange = results.filter(
            v => v.date >= this.state.form.date.toLocaleDateString('fr-CA')
                && v.date <= this.state.form.toDate.toLocaleDateString('fr-CA'))
        return (
            <>
                <StatisticsFilters
                    classes={classes}
                    labs={labs}
                    form={this.state.form}
                    params={this.state.params}
                    handleChangeForm={(e) => this.handleChangeForm(e)}
                />

                {this.state.chart === 'Rechart' &&
                    <StatisticsChart
                        classes={classes}
                        results={resultsRange}
                    />
                }

                {this.state.chart === 'VictoryBar' &&
                    <StatisticsChartVictory
                        classes={classes}
                        results={resultsRange}
                    />
                }

                {this.state.chart === 'VictoryBar Zoom' &&
                    <StatisticsChartVictory2
                        classes={classes}
                        results={resultsRange}
                    />
                }
                {this.state.chart === 'Reactive' &&
                    <StatisticsChartReactive
                        classes={classes}
                        results={resultsRange}
                    />
                }
                {this.state.chart === 'Stock' &&
                    <StatisticsChartStock
                        classes={classes}
                        form={this.state.form}
                        id={data ? data.id : null}
                        results={resultsRange}
                        parameters={parameters}
                        importAdd={(measure, id) => this.props.importAdd(measure, id)}
                    />
                }
                {this.state.chart !== 'Stock' &&
                    <StatisticsTable
                        classes={classes}
                        results={resultsRange}
                    />
                }
            </>
        );
    }
}

export default Statistics;