import React from 'react';
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';
import { signOut } from '../../../store/actions/authActions';
import { setMenu } from '../../../store/actions/measureActions';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Redirect, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { MoveToInbox, BarChart, ImportExport, Add, Group, ViewListOutlined, DnsOutlined, SettingsInputComponent, Timeline } from '@material-ui/icons';
import MailIcon from '@material-ui/icons/Mail';
import { withStyles, makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import { Tooltip } from '@material-ui/core';
import { TextField } from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#00205B'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    backgroundColor: '#00205B'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  active: {
    boxShadow: "rgba(0, 0, 0, 0.87) 4px 0px 0px inset",
    backgroundColor: '#a3b1ff',
  },
  inactive: {

  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const Navbar = (params) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { authStatus, profile, signOut, menu } = params;
  const { isEmpty, isLoaded } = authStatus;
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Measurements' })
    // dispatch({ type: actions.MEASURE_FORM, payload: { lab: 1, unit: 2 } })
  };
  const handleSubmitLabs = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Laboratory' })
  };

  const handleSubmitForm = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Percentiler' })
  };

  const handleSubmitImport = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Import' })
  };

  const handleSubmitStat = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Statistics' })
  };
  const handleSubmitStat2 = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Individual laboratories within one instrument group' })
  };
  const handleSubmitStat4 = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Group overview – differences between groups' })
  };

  const handleSubmitStat5 = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Basic figures and calculations' })
  };

  const handleSubmitStat6 = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Histogram for individual groups' })
  };
  const handleSubmitStat8 = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Histograms for individual groups' })
  };
  const handleSubmitStat7 = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Group overview – variation over time' })
  };
  const handleSubmitStat3 = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Monthly overview of lab instruments' })
  };

  const handleSubmitPermissions = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Permissions' })
  };

  const handleSubmitStat10 = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Daily median values of instruments' })
  };

  const handleSubmitStat9 = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Group overview - variation over time' })
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const theme = createMuiTheme({
    palette: {
      primary: green,
      backgroundColor: purple[700],
    },
  });

  const signout2 = () => {
    signOut();
  }
  console.log(',,,,,,,,,,,,,,,,,,,,',params)
  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' color='inherit' className={classes.title}>
            {params.menu}
          </Typography>
          {isLoaded &&
            (isEmpty ? (
              <SignedOutLinks></SignedOutLinks>
            ) : (
              <SignedInLinks
                signOut={signout2}
                profile={profile}
              ></SignedInLinks>
            ))}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>

          <Tooltip placement="left" title='Measurements' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmit()} disabled={menu == 'Measurements'} className={menu == 'Measurements' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <ViewListOutlined />
              </ListItemIcon>
              <ListItemText primary={"Survey"} />
            </ListItem>
          </Tooltip>
{/* 
          <Tooltip placement="left" title='Laboratory' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitLabs()} disabled={menu == 'Laboratory'} className={menu == 'Laboratory' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <DnsOutlined />
              </ListItemIcon>
              <ListItemText primary={"Labs"} />
            </ListItem>
          </Tooltip> */}

          <Tooltip placement="left" title='Percentiler' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitForm()} disabled={menu == 'Percentiler'} className={menu == 'Percentiler' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText primary={"Percentiler"} />
            </ListItem>
          </Tooltip>

          <Tooltip placement="left" title='Import' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitImport()} disabled={menu == 'Import'} className={menu == 'Import' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <ImportExport />
              </ListItemIcon>
              <ListItemText primary={"Import"} />
            </ListItem>
          </Tooltip>

          <Tooltip placement="left" title='Statistics' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitStat()} disabled={menu == 'Statistics'} className={menu == 'Statistics' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <Timeline />
              </ListItemIcon>
              <ListItemText primary={"Statistics"} />
            </ListItem>
          </Tooltip>

          <Tooltip placement="left" title='Daily median values of instruments' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitStat10()} disabled={menu == 'Daily median values of instruments'} className={menu == 'Daily median values of instruments' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <Timeline />
              </ListItemIcon>
              <ListItemText primary={"Daily median values of instruments"} />
            </ListItem>
          </Tooltip>

          <Tooltip placement="left" title='Group overview - variation over time' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitStat9()} disabled={menu == 'Group overview - variation over time'} className={menu == 'Group overview - variation over time' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <Timeline />
              </ListItemIcon>
              <ListItemText primary={"Group overview - variation over time"} />
            </ListItem>
          </Tooltip>
          
          <Tooltip placement="left" title='Individual laboratories within one instrument group' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitStat2()} disabled={menu == 'Individual laboratories within one instrument group'} className={menu == 'Individual laboratories within one instrument group' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <SettingsInputComponent />
              </ListItemIcon>
              <ListItemText primary={"Individual laboratories within one instrument group"} />
            </ListItem>
          </Tooltip>

          <Tooltip placement="left" title='Group overview – differences between groups' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitStat4()} disabled={menu == 'Group overview – differences between groups'} className={menu == 'Group overview – differences between groups' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <SettingsInputComponent />
              </ListItemIcon>
              <ListItemText primary={"Group overview – differences between groups"} />
            </ListItem>
          </Tooltip>

          <Tooltip placement="left" title='Monthly overview of lab instruments' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitStat3()} disabled={menu == 'Monthly overview of lab instruments'} className={menu == 'Monthly overview of lab instruments' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <SettingsInputComponent />
              </ListItemIcon>
              <ListItemText primary={"Monthly overview of lab instruments"} />
            </ListItem>
          </Tooltip>

          {/* <Tooltip placement="left" title='Basic figures and calculations' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitStat5()} disabled={menu == 'Basic figures and calculations'} className={menu == 'Basic figures and calculations' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <Timeline />
              </ListItemIcon>
              <ListItemText primary={"Basic figures and calculations"} />
            </ListItem>
          </Tooltip> */}

          <Tooltip placement="left" title='Histogram for individual groups' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitStat6()} disabled={menu == 'Histogram for individual groups'} className={menu == 'Histogram for individual groups' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <BarChart />
              </ListItemIcon>
              <ListItemText primary={"Histogram for individual groups"} />
            </ListItem>
          </Tooltip>

          <Tooltip placement="left" title='Histograms for individual groups' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitStat8()} disabled={menu == 'Histograms for individual groups'} className={menu == 'Histograms for individual groups' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <BarChart />
              </ListItemIcon>
              <ListItemText primary={"Histograms for individual groups"} />
            </ListItem>
          </Tooltip>

          {/* <Tooltip placement="left" title='Group overview  variation over time' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitStat7()} disabled={menu == 'Group overview – variation over time'} className={menu == 'Group overview – variation over time' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <Timeline />
              </ListItemIcon>
              <ListItemText primary={"Group overview – variation over time"} />
            </ListItem>
          </Tooltip> */}

          {/* <Tooltip placement="left" title='Permissions' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitPermissions()} disabled={menu == 'Permissions'} className={menu == 'Permissions' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <Group />
              </ListItemIcon>
              <ListItemText primary={"Permissions"} />
            </ListItem>
          </Tooltip> */}
        </List>
        <Divider />
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authStatus: state.firebase.auth,
    profile: state.firebase.profile,
    menu: state.measure.menu,
    state: state.measure.update
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    setMenu: (a) => dispatch(setMenu(a)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
