export const params = [
    "Albumin",
    "Alkaline Phosphatase",
    "ALT - GPT",
    "AST - GOT",
    "Calcium",
    "Chloride",
    "Creatinine",
    "FT4",
    "GGT",
    "Glucose",
    "Inorganic phosphor",
    "LDH",
    "Magnesium",
    "Potassium",
    "Sodium",
    "total-Bilirubin",
    "total-Cholesterol",
    "total-Protein",
    "TSH",
    "Urea",
    "Uric acid",
  ];

  export const ft4median = [
    { x: 11.97478111 },
    { x: 11.0424 },
    { x: 10.6572 },
    { x: 11.54930495 },
    { x: 10.914 },
    { x: 12.61629608 },
    { x: 10.272 },
    { x: 12.47295204 },
    { x: 10.914 },
    { x: 12.91193941 },
    { x: 11.81403952 },
    { x: 10.7856 },
    { x: 12.24865391 },
    { x: 10.7856 },
    { x: 12.25281702 },
    { x: 10.4004 },
    { x: 12.68540933 },
    { x: 10.272 },
    { x: 10.0152 },
    { x: 12.76347898 },
    { x: 12.45598968 },
    { x: 10.914 },
    { x: 11.0424 },
    { x: 12.59535475 },
    { x: 10.272 },
    { x: 12.12143847 },
    { x: 10.6572 },
    { x: 11.92964059 },
    { x: 10.914 },
    { x: 12.03831077 },
    { x: 10.914 },
    { x: 11.55387293 },
    { x: 12.47798265 },
    { x: 10.6572 },
    { x: 11.1708 },
    { x: 12.48105694 },
    { x: 11.1708 },
    { x: 13.14581668 },
    { x: 12.44267861 },
    { x: 11.0424 },
    { x: 10.6572 },
    { x: 12.68706314 },
    { x: 11.0424 },
    { x: 12.45848239 },
    { x: 12.59973573 },
    { x: 10.4004 },
    { x: 11.2992 },
    { x: 12.8683023 },
    { x: 10.914 },
    { x: 12.35264346 },
    { x: 12.93332679 },
    { x: 10.7856 },
    { x: 10.5288 },
    { x: 11.90442693 },
    { x: 11.94569084 },
    { x: 11.0424 },
    { x: 12.39857143 },
    { x: 11.4276 },
    { x: 11.1708 },
    { x: 12.42885001 },
    { x: 11.9412 },
    { x: 11.1708 },
    { x: 11.6844 },
    { x: 13.8672 },
    { x: 12.84 },
    { x: 11.4276 },
    { x: 11.556 },
    { x: 12.198 },
    { x: 12.3264 },
    { x: 11.556 },
    { x: 11.2992 },
    { x: 11.556 },
    { x: 11.6844 },
    { x: 11.4276 },
    { x: 12.4548 },
    { x: 12.198 },
    { x: 11.556 },
    { x: 12.4548 },
    { x: 12.5832 },
    { x: 10.914 },
    { x: 11.2992 },
    { x: 12.0696 },
    { x: 11.1708 },
    { x: 12.3264 },
    { x: 11.9412 },
    { x: 12.3264 },
    { x: 12.0696 },
    { x: 11.556 },
    { x: 13.0968 },
    { x: 11.8128 },
    { x: 11.8128 },
    { x: 11.6844 },
    { x: 11.556 },
    { x: 11.8128 },
    { x: 11.1708 },
    { x: 11.556 },
    { x: 12.84 },
    { x: 11.1708 },
    { x: 11.6844 },
    { x: 12.0696 },
    { x: 11.6844 },
    { x: 12.5832 },
    { x: 11.4276 },
    { x: 12.0696 },
    { x: 11.8128 },
    { x: 12.0696 },
    { x: 11.556 },
    { x: 12.198 },
    { x: 11.2992 },
    { x: 11.8128 },
    { x: 11.556 },
    { x: 11.6844 },
    { x: 11.6844 },
    { x: 11.9412 },
    { x: 11.2992 },
    { x: 11.6844 },
    { x: 11.2992 },
    { x: 11.556 },
    { x: 12.0696 },
    { x: 11.1708 },
    { x: 11.4276 },
    { x: 11.4276 },
    { x: 11.8128 },
    { x: 11.2992 },
    { x: 11.1708 },
    { x: 11.6844 },
    { x: 11.6844 },
    { x: 11.2992 },
    { x: 11.9412 },
    { x: 11.8128 },
    { x: 11.6844 },
    { x: 11.4276 },
    { x: 11.6844 },
    { x: 12.198 },
    { x: 11.556 },
    { x: 12.198 },
    { x: 11.6844 },
    { x: 11.9412 },
    { x: 11.6844 },
    { x: 11.9412 },
    { x: 11.8128 },
    { x: 11.6844 },
    { x: 11.6844 },
    { x: 11.8128 },
    { x: 12.198 },
    { x: 11.9412 },
    { x: 12.0696 },
    { x: 11.9412 },
    { x: 11.8128 },
    { x: 11.6844 },
    { x: 11.8128 },
    { x: 11.4276 },
    { x: 11.4276 },
    { x: 11.4276 },
    { x: 11.2992 },
    { x: 12.0696 },
    { x: 11.8128 },
    { x: 11.6844 },
    { x: 12.198 },
    { x: 11.8128 },
    { x: 11.556 },
    { x: 11.2992 },
    { x: 11.0424 },
    { x: 12.198 },
    { x: 12.7116 },
    { x: 10.1436 },
    { x: 10.4004 },
    { x: 12.0696 },
    { x: 11.6844 },
    { x: 10.0152 },
    { x: 10.4004 },
    { x: 12.5832 },
    { x: 12.3264 },
    { x: 9.8868 },
    { x: 9.8868 },
    { x: 12.0696 },
    { x: 13.9956 },
    { x: 14.5092 },
    { x: 9.7584 },
    { x: 12.0696 },
    { x: 10.0152 },
    { x: 12.7116 },
    { x: 11.556 },
    { x: 10.0152 },
    { x: 12.83485212 },
    { x: 10.272 },
    { x: 11.8128 },
    { x: 11.21007703 },
    { x: 12.39178342 },
    { x: 11.8128 },
    { x: 12.18326106 },
    { x: 11.9412 },
    { x: 11.35347997 },
    { x: 12.198 },
    { x: 12.198 },
    { x: 12.08854929 },
    { x: 12.5832 },
    { x: 11.99624731 },
    { x: 11.8128 },
    { x: 11.47138161 },
    { x: 12.3264 },
    { x: 11.1004021 },
    { x: 12.27489568 },
    { x: 11.9412 },
    { x: 11.6844 },
    { x: 11.77532199 },
    { x: 11.8128 },
    { x: 11.05150392 },
    { x: 11.8128 },
    { x: 11.56704609 },
    { x: 11.9412 },
    { x: 12.06190542 },
    { x: 12.0696 },
    { x: 12.19042068 },
    { x: 12.32310363 },
    { x: 11.9412 },
    { x: 12.41179377 },
    { x: 11.4276 },
    { x: 11.92463905 },
    { x: 12.0696 },
    { x: 11.9412 },
    { x: 11.90046731 },
    { x: 12.4616012 },
    { x: 12.5832 },
    { x: 12.58157295 },
    { x: 11.2992 },
    { x: 11.6844 },
    { x: 11.18205842 },
    { x: 10.5288 },
    { x: 12.16338223 },
    { x: 12.4548 },
    { x: 12.34031246 },
    { x: 11.57211273 },
    { x: 12.0696 },
    { x: 11.84031846 },
    { x: 12.198 },
    { x: 11.20734357 },
    { x: 12.5832 },
    { x: 11.2226384 },
    { x: 11.6844 },
    { x: 11.50936765 },
    { x: 11.8128 },
    { x: 12.198 },
    { x: 11.35369842 },
    { x: 12.2280923 },
    { x: 11.65156284 },
    { x: 12.17716746 },
    { x: 12.38881663 },
    { x: 12.20005301 },
    { x: 13.28147213 },
    { x: 12.20005301 },
    { x: 11.85702472 },
    { x: 10.90930188 },
    { x: 12.33855582 },
    { x: 11.59631935 },
    { x: 12.32255588 },
    { x: 12.7565744 },
    { x: 11.17690944 },
    { x: 11.59598653 },
    { x: 12.94756826 },
    { x: 12.07878486 },
    { x: 12.45144972 },
    { x: 11.53748814 },
    { x: 12.45031507 },
    { x: 11.30263749 },
    { x: 12.76299634 },
    { x: 12.45975868 },
    { x: 11.95054932 },
    { x: 12.66575459 },
    { x: 12.89997163 },
    { x: 12.40479426 },
    { x: 12.89997163 },
    { x: 10.95571029 },
    { x: 11.08358499 },
    { x: 11.83482531 },
    { x: 12.43048885 },
    { x: 11.30411402 },
    { x: 12.08393222 },
    { x: 11.34815152 },
    { x: 12.09449543 },
    { x: 11.72463104 },
    { x: 12.72818409 },
    { x: 11.25764622 },
    { x: 13.3294073 },
    { x: 12.02764901 },
    { x: 12.87184322 },
    { x: 12.54053664 },
    { x: 11.79710575 },
    { x: 11.72409007 },
    { x: 12.31812172 },
    { x: 11.77909223 },
    { x: 12.03390634 },
    { x: 12.39816198 },
    { x: 12.55116285 },
    { x: 12.60817002 },
    { x: 11.7894794 },
    { x: 12.22828444 },
    { x: 12.27381373 },
    { x: 11.85712422 },
    { x: 11.50024885 },
    { x: 12.41989124 },
    { x: 13.29765334 },
    { x: 12.15075348 },
    { x: 12.48115978 },
    { x: 11.56916596 },
    { x: 12.17712858 },
    { x: 12.24028415 },
    { x: 12.05717283 },
    { x: 11.90905552 },
    { x: 11.65608735 },
    { x: 11.51510622 },
    { x: 11.90868267 },
    { x: 12.89419694 },
    { x: 11.59805903 },
    { x: 11.29909084 },
    { x: 12.85419578 },
    { x: 12.58823401 },
    { x: 10.80457463 },
    { x: 13.32680422 },
    { x: 12.61075586 },
    { x: 11.66485389 },
    { x: 11.09684411 },
    { x: 11.93714724 },
    { x: 12.72461228 },
    { x: 12.19915748 },
    { x: 12.9683998 },
    { x: 11.38015815 },
    { x: 12.11680416 },
    { x: 11.34195938 },
    { x: 12.03242123 },
    { x: 11.56050064 },
    { x: 12.45273239 },
    { x: 12.74943651 },
    { x: 11.94500224 },
    { x: 12.0927365 },
    { x: 11.31093167 },
    { x: 12.98372323 },
    { x: 12.59114466 },
    { x: 12.01012623 },
    { x: 12.19730019 },
    { x: 12.12845228 },
    { x: 11.33789921 },
    { x: 12.64277974 },
    { x: 12.48776709 },
    { x: 11.98070552 },
    { x: 12.60058141 },
    { x: 12.43616575 },
    { x: 11.6081191 },
    { x: 11.67005892 },
    { x: 11.91709686 },
    { x: 12.46538868 },
    { x: 12.5962113 },
    { x: 11.66193409 },
    { x: 12.07957468 },
    { x: 12.10358335 },
    { x: 11.17225577 },
    { x: 11.53971142 },
    { x: 12.4442066 },
    { x: 11.80645892 },
    { x: 11.62539818 },
    { x: 11.86517365 },
    { x: 11.53118049 },
    { x: 11.04674961 },
    { x: 11.88255118 },
    { x: 11.89684633 },
    { x: 11.8269136 },
    { x: 13.07596115 },
    { x: 12.36620549 },
    { x: 12.52553354 },
    { x: 11.15536652 },
    { x: 12.46512849 },
    { x: 12.45427411 },
    { x: 11.72159335 },
    { x: 11.6599326 },
    { x: 12.23233308 },
    { x: 11.18137677 },
    { x: 12.50850827 },
    { x: 11.80403377 },
    { x: 12.01791261 },
    { x: 12.28552713 },
    { x: 11.88778757 },
    { x: 13.8211889 },
    { x: 11.28706473 },
    { x: 12.33226475 },
    { x: 11.65396062 },
    { x: 11.80576707 },
    { x: 12.18855987 },
    { x: 11.78451808 },
    { x: 11.75500055 },
    { x: 12.10138237 },
    { x: 11.98676833 },
    { x: 12.21510652 },
    { x: 12.52454528 },
    { x: 11.86041468 },
    { x: 11.32525673 },
    { x: 11.81560631 },
    { x: 12.27212399 },
    { x: 12.16757851 },
    { x: 11.58397643 },
    { x: 12.18893738 },
    { x: 12.04106139 },
    { x: 11.93006481 },
    { x: 11.67887815 },
    { x: 12.62294094 },
    { x: 12.04327895 },
    { x: 12.20022694 },
    { x: 12.21574528 },
    { x: 11.89740798 },
    { x: 11.13777978 },
    { x: 12.21437741 },
    { x: 11.57431552 },
    { x: 12.14558162 },
    { x: 12.83227085 },
    { x: 11.54329753 },
    { x: 12.24339276 },
    { x: 12.20873557 },
  ];

  export const ft4hypo1 = [
    { x: 3.0 },
    { x: 2.0 },
    { x: 4.0 },
    { x: 5.0 },
    { x: 8.0 },
    { x: 6.0 },
    { x: 6.0 },
    { x: 7.0 },
    { x: 5.0 },
    { x: 5.0 },
    { x: 5.0 },
    { x: 5.0 },
    { x: 6.0 },
    { x: 4.0 },
    { x: 2.0 },
    { x: 3.0 },
    { x: 25.0 },
    { x: 7.0 },
    { x: 7.0 },
    { x: 9.0 },
    { x: 3.0 },
    { x: 5.0 },
    { x: 13.0 },
    { x: 11.0 },
    { x: 5.0 },
    { x: 4.0 },
    { x: 17.0 },
    { x: 8.0 },
    { x: 11.0 },
    { x: 13.0 },
    { x: 5.0 },
    { x: 4.0 },
    { x: 4.0 },
    { x: 3.0 },
    { x: 6.0 },
    { x: 9.0 },
    { x: 2.0 },
    { x: 4.0 },
    { x: 5.0 },
    { x: 9.0 },
    { x: 5.0 },
    { x: 15.0 },
    { x: 11.0 },
    { x: 4.0 },
    { x: 17.0 },
    { x: 8.0 },
    { x: 9.0 },
    { x: 6.0 },
    { x: 2.0 },
    { x: 5.0 },
    { x: 8.0 },
    { x: 9.0 },
    { x: 2.0 },
    { x: 5.0 },
    { x: 2.0 },
    { x: 3.0 },
    { x: 6.0 },
    { x: 14.0 },
    { x: 5.0 },
    { x: 6.0 },
    { x: 5.0 },
    { x: 5.0 },
    { x: 3.0 },
    { x: 13.0 },
    { x: 5.0 },
    { x: 15.0 },
    { x: 4.0 },
    { x: 9.0 },
    { x: 12.0 },
    { x: 13.0 },
    { x: 12.0 },
    { x: 4.0 },
    { x: 5.0 },
    { x: 2.0 },
    { x: 4.0 },
    { x: 5.0 },
    { x: 6.0 },
    { x: 15.0 },
    { x: 5.0 },
    { x: 2.0 },
    { x: 14.0 },
    { x: 5.0 },
    { x: 4.0 },
    { x: 4.0 },
    { x: 12.0 },
    { x: 4.0 },
    { x: 7.0 },
    { x: 5.0 },
    { x: 9.0 },
    { x: 3.0 },
    { x: 3.0 },
    { x: 4.0 },
    { x: 3.0 },
    { x: 3.0 },
    { x: 4.0 },
    { x: 3.0 },
    { x: 9.0 },
    { x: 3.0 },
    { x: 8.0 },
    { x: 6.0 },
    { x: 10.0 },
    { x: 10.0 },
    { x: 10.0 },
    { x: 13.0 },
    { x: 12.0 },
    { x: 9.0 },
    { x: 13.0 },
    { x: 4.0 },
    { x: 2.0 },
    { x: 9.0 },
    { x: 6.0 },
    { x: 6.0 },
    { x: 11.0 },
    { x: 2.0 },
    { x: 3.0 },
    { x: 15.0 },
    { x: 8.0 },
    { x: 5.0 },
    { x: 5.0 },
    { x: 3.0 },
    { x: 11.0 },
    { x: 6.0 },
    { x: 4.0 },
    { x: 5.0 },
    { x: 4.0 },
    { x: 12.0 },
    { x: 3.0 },
    { x: 10.0 },
    { x: 7.0 },
    { x: 13.0 },
    { x: 3.0 },
    { x: 2.0 },
    { x: 4.0 },
    { x: 12.0 },
    { x: 10.0 },
    { x: 7.0 },
    { x: 12.0 },
    { x: 9.0 },
    { x: 7.0 },
    { x: 6.0 },
    { x: 2.0 },
    { x: 8.0 },
    { x: 3.0 },
    { x: 3.0 },
    { x: 8.0 },
    { x: 8.0 },
    { x: 9.0 },
    { x: 3.0 },
    { x: 5.0 },
    { x: 10.0 },
    { x: 5.0 },
    { x: 5.0 },
    { x: 4.0 },
    { x: 3.0 },
    { x: 14.0 },
    { x: 8.0 },
    { x: 7.0 },
    { x: 11.0 },
    { x: 5.0 },
    { x: 2.0 },
    { x: 8.0 },
    { x: 7.0 },
    { x: 20.0 },
    { x: 4.0 },
    { x: 8.0 },
    { x: 10.0 },
    { x: 6.0 },
    { x: 3.0 },
    { x: 5.0 },
    { x: 7.0 },
    { x: 7.0 },
    { x: 5.0 },
    { x: 3.0 },
    { x: 1.0 },
    { x: 13.0 },
    { x: 12.0 },
    { x: 8.0 },
    { x: 8.0 },
    { x: 14.0 },
    { x: 2.0 },
    { x: 2.0 },
    { x: 1.0 },
    { x: 10.0 },
    { x: 2.0 },
    { x: 8.0 },
    { x: 3.0 },
    { x: 8.0 },
    { x: 4.0 },
    { x: 14.0 },
    { x: 8.0 },
    { x: 11.0 },
    { x: 10.0 },
    { x: 7.0 },
    { x: 7.0 },
    { x: 5.0 },
    { x: 5.0 },
    { x: 6.0 },
    { x: 8.0 },
    { x: 7.0 },
    { x: 4.0 },
    { x: 12.3 },
    { x: 6.0 },
    { x: 12.3 },
    { x: 11.6 },
    { x: 8.2 },
    { x: 10.0 },
    { x: 6.7 },
    { x: 11.0 },
    { x: 7.0 },
    { x: 4.0 },
    { x: 9.0 },
    { x: 15.1 },
    { x: 3.0 },
    { x: 11.5 },
    { x: 4.0 },
    { x: 1.0 },
    { x: 10.1 },
    { x: 8.8 },
    { x: 3.0 },
    { x: 11.5 },
    { x: 13.0 },
    { x: 9.2 },
    { x: 10.2 },
    { x: 2.0 },
    { x: 9.0 },
    { x: 5.2 },
    { x: 8.0 },
    { x: 9.3 },
    { x: 7.0 },
    { x: 8.0 },
    { x: 5.5 },
    { x: 6.3 },
    { x: 12.3 },
    { x: 2.0 },
    { x: 3.0 },
    { x: 7.0 },
    { x: 11.6 },
    { x: 10.0 },
    { x: 9.2 },
    { x: 10.7 },
    { x: 4.0 },
    { x: 9.0 },
    { x: 9.9 },
    { x: 13.9 },
    { x: 5.0 },
    { x: 14.4 },
    { x: 2.0 },
    { x: 7.0 },
    { x: 10.0 },
    { x: 2.0 },
    { x: 15.1 },
    { x: 2.0 },
    { x: 7.4 },
    { x: 2.0 },
    { x: 7.4 },
    { x: 10.5 },
    { x: 7.0 },
    { x: 3.9 },
    { x: 8.0 },
    { x: 5.0 },
    { x: 9.0 },
    { x: 12.0 },
    { x: 7.8 },
    { x: 11.1 },
    { x: 2.0 },
    { x: 12.6 },
    { x: 6.0 },
    { x: 7.0 },
    { x: 7.0 },
    { x: 7.2 },
    { x: 16.5 },
    { x: 6.0 },
    { x: 9.0 },
    { x: 13.4 },
    { x: 5.0 },
    { x: 14.2 },
    { x: 9.4 },
    { x: 10.0 },
    { x: 3.0 },
    { x: 10.8 },
    { x: 9.7 },
    { x: 9.1 },
    { x: 4.0 },
    { x: 6.0 },
    { x: 10.9 },
    { x: 1.0 },
    { x: 11.0 },
    { x: 9.0 },
    { x: 9.4 },
    { x: 13.2 },
    { x: 7.0 },
    { x: 12.2 },
    { x: 2.0 },
    { x: 8.1 },
    { x: 7.0 },
    { x: 10.4 },
    { x: 3.0 },
    { x: 11.7 },
    { x: 7.0 },
    { x: 4.5 },
    { x: 3.0 },
    { x: 13.6 },
    { x: 2.0 },
    { x: 7.0 },
    { x: 8.9 },
    { x: 12.9 },
    { x: 10.1 },
    { x: 5.0 },
    { x: 6.0 },
    { x: 11.3 },
    { x: 11.4 },
    { x: 4.0 },
    { x: 3.7 },
    { x: 17.0 },
    { x: 9.8 },
    { x: 2.0 },
    { x: 14.0 },
    { x: 10.0 },
    { x: 10.4 },
    { x: 7.0 },
    { x: 10.3 },
    { x: 9.0 },
    { x: 10.1 },
    { x: 10.0 },
    { x: 6.0 },
    { x: 7.0 },
    { x: 11.8 },
    { x: 2.0 },
    { x: 8.9 },
    { x: 17.8 },
    { x: 4.0 },
    { x: 10.7 },
    { x: 5.0 },
    { x: 3.0 },
    { x: 21.1 },
    { x: 15.1 },
    { x: 17.3 },
    { x: 10.0 },
    { x: 15.3 },
    { x: 26.5 },
    { x: 4.0 },
    { x: 22.9 },
    { x: 2.0 },
    { x: 15.0 },
    { x: 13.4 },
    { x: 8.0 },
    { x: 7.0 },
    { x: 13.6 },
    { x: 7.0 },
    { x: 16.3 },
    { x: 13.0 },
    { x: 7.0 },
    { x: 6.0 },
    { x: 11.0 },
    { x: 7.0 },
    { x: 18.5 },
    { x: 2.0 },
    { x: 11.0 },
    { x: 16.0 },
    { x: 14.6 },
    { x: 9.0 },
    { x: 12.9 },
    { x: 10.0 },
    { x: 15.6 },
    { x: 8.0 },
    { x: 10.0 },
    { x: 22.1 },
    { x: 6.0 },
    { x: 20.8 },
    { x: 22.3 },
    { x: 8.0 },
    { x: 16.0 },
    { x: 17.0 },
    { x: 8.0 },
    { x: 7.0 },
    { x: 8.0 },
    { x: 8.0 },
    { x: 13.3 },
    { x: 22.4 },
    { x: 21.5 },
  ];
export const ft4 = [
    {x:new Date(`2015-01-01`),m1:11.9747811057458,m2:10.4004,m3:12.1284522756331,hypo1:4.0,hypo2:3.0,hypo3:6.0,hyper1:2.0,hyper2:6.0,hyper3:0.0},
    {x:new Date(`2015-01-02`),m1:11.0423999999999,m2:12.5832,m3:11.337899211224,hypo1:6.0,hypo2:4.0,hypo3:2.0,hyper1:6.0,hyper2:4.0,hyper3:0.0},
    {x:new Date(`2015-01-05`),m1:10.6572,m2:12.3264,m3:11.6539606245732,hypo1:3.0,hypo2:3.0,hypo3:7.0,hyper1:7.0,hyper2:3.0,hyper3:5.0},
    {x:new Date(`2015-01-06`),m1:11.5493049547921,m2:9.8868,m3:11.8057670655229,hypo1:6.0,hypo2:9.0,hypo3:7.0,hyper1:6.0,hyper2:6.0,hyper3:0.0},
    {x:new Date(`2015-01-07`),m1:10.914,m2:9.8868,m3:12.1885598670684,hypo1:0.0,hypo2:0.0,hypo3:9.0,hyper1:0.0,hyper2:4.0,hyper3:6.0},
    {x:new Date(`2015-01-08`),m1:12.6162960793839,m2:12.0696,m3:11.7845180764833,hypo1:3.0,hypo2:8.0,hypo3:6.0,hyper1:6.0,hyper2:2.0,hyper3:6.0},
    {x:new Date(`2015-01-09`),m1:10.272,m2:13.9956,m3:11.7550005453285,hypo1:6.0,hypo2:10.0,hypo3:5.0,hyper1:3.0,hyper2:2.0,hyper3:4.0},
    {x:new Date(`2015-01-12`),m1:12.4729520394032,m2:14.5091999999999,m3:12.1013823742884,hypo1:7.0,hypo2:6.0,hypo3:0.0,hyper1:5.0,hyper2:5.0,hyper3:0.0},
    {x:new Date(`2015-01-13`),m1:10.914,m2:9.7584,m3:11.9867683307915,hypo1:0.0,hypo2:10.0,hypo3:10.0,hyper1:0.0,hyper2:8.0,hyper3:5.0},
    {x:new Date(`2015-01-14`),m1:12.9119394090457,m2:12.0696,m3:12.2151065210648,hypo1:2.0,hypo2:0.0,hypo3:3.0,hyper1:0.0,hyper2:7.0,hyper3:17.0},
    {x:new Date(`2015-01-15`),m1:11.8140395203226,m2:10.0152,m3:12.524545281363,hypo1:15.0,hypo2:10.0,hypo3:4.0,hyper1:7.0,hyper2:7.0,hyper3:3.0},
    {x:new Date(`2015-01-16`),m1:10.7855999999999,m2:12.7115999999999,m3:11.860414675475,hypo1:6.0,hypo2:0.0,hypo3:0.0,hyper1:6.0,hyper2:3.0,hyper3:0.0},
    {x:new Date(`2015-01-19`),m1:12.2486539145847,m2:11.556,m3:11.3252567306198,hypo1:11.0,hypo2:12.0,hypo3:1.0,hyper1:6.0,hyper2:6.0,hyper3:3.0},
    {x:new Date(`2015-01-20`),m1:10.7855999999999,m2:10.0152,m3:11.8156063127918,hypo1:6.0,hypo2:13.0,hypo3:6.0,hyper1:6.0,hyper2:0.0,hyper3:9.0},
    {x:new Date(`2015-01-21`),m1:12.2528170191707,m2:12.83485211759,m3:12.2721239885435,hypo1:2.0,hypo2:9.0,hypo3:7.0,hyper1:9.0,hyper2:4.0,hyper3:9.0},
    {x:new Date(`2015-01-22`),m1:10.4004,m2:10.272,m3:12.1675785061808,hypo1:16.0,hypo2:13.0,hypo3:9.0,hyper1:2.0,hyper2:6.0,hyper3:1.0},
    {x:new Date(`2015-01-23`),m1:12.6854093340625,m2:11.8128,m3:11.5839764346907,hypo1:2.0,hypo2:4.0,hypo3:7.0,hyper1:8.0,hyper2:4.0,hyper3:6.0},
    {x:new Date(`2015-01-26`),m1:10.272,m2:11.2100770322019,m3:12.1889373816861,hypo1:0.0,hypo2:0.0,hypo3:2.0,hyper1:7.0,hyper2:0.0,hyper3:5.0},
    {x:new Date(`2015-01-27`),m1:10.0152,m2:12.3917834186301,m3:12.0410613906278,hypo1:17.0,hypo2:2.0,hypo3:3.0,hyper1:7.0,hyper2:0.0,hyper3:0.0},
    {x:new Date(`2015-01-28`),m1:12.7634789821513,m2:11.8128,m3:11.9300648117218,hypo1:2.0,hypo2:9.0,hypo3:7.0,hyper1:2.0,hyper2:6.0,hyper3:13.0},
    {x:new Date(`2015-01-29`),m1:12.4559896756351,m2:12.1832610584016,m3:11.6788781539025,hypo1:6.0,hypo2:0.0,hypo3:2.0,hyper1:0.0,hyper2:5.0,hyper3:2.0},
    {x:new Date(`2015-01-30`),m1:10.914,m2:11.9412,m3:12.6229409444801,hypo1:3.0,hypo2:6.0,hypo3:3.0,hyper1:6.0,hyper2:9.0,hyper3:7.0},
    {x:new Date(`2015-02-02`),m1:11.0423999999999,m2:11.3534799732503,m3:12.0432789541161,hypo1:2.0,hypo2:0.0,hypo3:0.0,hyper1:10.0,hyper2:8.0,hyper3:10.0},
    {x:new Date(`2015-02-03`),m1:12.5953547483481,m2:12.1979999999999,m3:12.2002269436006,hypo1:14.0,hypo2:0.0,hypo3:7.0,hyper1:11.0,hyper2:5.0,hyper3:0.0},
    {x:new Date(`2015-02-04`),m1:10.272,m2:12.1979999999999,m3:12.2157452831283,hypo1:0.0,hypo2:0.0,hypo3:5.0,hyper1:5.0,hyper2:2.0,hyper3:0.0},
    {x:new Date(`2015-02-05`),m1:12.1214384737447,m2:12.0885492853591,m3:11.8974079770782,hypo1:0.0,hypo2:6.0,hypo3:6.0,hyper1:3.0,hyper2:0.0,hyper3:3.0},
    {x:new Date(`2015-02-06`),m1:10.6572,m2:12.5832,m3:11.1377797790418,hypo1:9.0,hypo2:2.0,hypo3:4.0,hyper1:3.0,hyper2:3.0,hyper3:0.0},
    {x:new Date(`2015-02-09`),m1:11.9296405855936,m2:11.9962473093943,m3:12.2143774059072,hypo1:7.0,hypo2:11.0,hypo3:0.0,hyper1:4.0,hyper2:5.0,hyper3:0.0},
    {x:new Date(`2015-02-10`),m1:10.914,m2:11.8128,m3:11.574315515889,hypo1:2.0,hypo2:3.0,hypo3:2.0,hyper1:0.0,hyper2:7.0,hyper3:5.0},
    {x:new Date(`2015-02-11`),m1:12.0383107679549,m2:11.4713816112891,m3:12.1455816210686,hypo1:0.0,hypo2:15.0,hypo3:17.0,hyper1:6.0,hyper2:0.0,hyper3:4.0},
    {x:new Date(`2015-02-12`),m1:10.914,m2:12.3264,m3:12.8322708543054,hypo1:3.0,hypo2:5.0,hypo3:10.0,hyper1:0.0,hyper2:8.0,hyper3:6.0},
    {x:new Date(`2015-02-13`),m1:11.5538729327362,m2:11.1004021005607,m3:11.5432975274479,hypo1:10.0,hypo2:8.0,hypo3:7.0,hyper1:0.0,hyper2:4.0,hyper3:2.0},
    {x:new Date(`2015-02-16`),m1:12.477982648063,m2:12.2748956795516,m3:12.2433927580488,hypo1:2.0,hypo2:3.0,hypo3:6.0,hyper1:16.0,hyper2:12.0,hyper3:8.0},
    {x:new Date(`2015-02-17`),m1:10.6572,m2:11.9412,m3:12.2087355745258,hypo1:5.0,hypo2:5.0,hypo3:9.0,hyper1:0.0,hyper2:8.0,hyper3:3.0},
    {x:new Date(`2015-02-18`),m1:11.1708,m2:11.6844,m3:12.6427797390267,hypo1:4.0,hypo2:11.0,hypo3:7.0,hyper1:3.0,hyper2:5.0,hyper3:8.0},
    {x:new Date(`2015-02-19`),m1:12.4810569406803,m2:11.7753219855166,m3:12.4877670875502,hypo1:2.0,hypo2:6.0,hypo3:2.0,hyper1:7.0,hyper2:9.0,hyper3:5.0},
    {x:new Date(`2015-02-20`),m1:11.1708,m2:11.8128,m3:11.9807055238106,hypo1:5.0,hypo2:5.0,hypo3:4.0,hyper1:2.0,hyper2:2.0,hyper3:6.0},
    {x:new Date(`2015-02-23`),m1:13.1458166773972,m2:11.0515039210355,m3:12.0883462768251,hypo1:8.0,hypo2:4.0,hypo3:5.0,hyper1:5.0,hyper2:4.0,hyper3:8.0},
    {x:new Date(`2015-02-24`),m1:12.4426786062053,m2:11.8128,m3:12.6099324766595,hypo1:2.0,hypo2:12.0,hypo3:0.0,hyper1:11.0,hyper2:6.0,hyper3:1.0},
    {x:new Date(`2015-02-25`),m1:11.0423999999999,m2:11.5670460948042,m3:11.9960917648274,hypo1:3.0,hypo2:4.0,hypo3:3.0,hyper1:5.0,hyper2:2.0,hyper3:0.0},
    {x:new Date(`2015-02-26`),m1:10.6572,m2:11.9412,m3:12.3986708405579,hypo1:11.0,hypo2:3.0,hypo3:10.0,hyper1:0.0,hyper2:3.0,hyper3:0.0},
    {x:new Date(`2015-02-27`),m1:12.6870631387964,m2:12.0619054160085,m3:11.9129635774833,hypo1:4.0,hypo2:10.0,hypo3:0.0,hyper1:8.0,hyper2:0.0,hyper3:2.0},
    {x:new Date(`2015-03-02`),m1:11.0423999999999,m2:12.0696,m3:12.2935959107423,hypo1:7.0,hypo2:13.0,hypo3:2.0,hyper1:7.0,hyper2:5.0,hyper3:2.0},
    {x:new Date(`2015-03-03`),m1:12.4584823916915,m2:12.1904206833216,m3:11.8809579954131,hypo1:13.0,hypo2:7.0,hypo3:4.0,hyper1:0.0,hyper2:3.0,hyper3:0.0},
    {x:new Date(`2015-03-04`),m1:12.5997357296989,m2:12.3231036300609,m3:12.4534174717302,hypo1:0.0,hypo2:2.0,hypo3:8.0,hyper1:0.0,hyper2:16.0,hyper3:3.0},
    {x:new Date(`2015-03-05`),m1:10.4004,m2:11.9412,m3:12.3052194359917,hypo1:2.0,hypo2:3.0,hypo3:0.0,hyper1:2.0,hyper2:2.0,hyper3:0.0},
    {x:new Date(`2015-03-06`),m1:11.2992,m2:12.4117937696843,m3:12.1914866210892,hypo1:0.0,hypo2:12.0,hypo3:7.0,hyper1:0.0,hyper2:7.0,hyper3:7.0},
    {x:new Date(`2015-03-09`),m1:12.8683022957556,m2:11.4276,m3:11.2636885311484,hypo1:0.0,hypo2:4.0,hypo3:7.0,hyper1:0.0,hyper2:4.0,hyper3:7.0},
    {x:new Date(`2015-03-10`),m1:10.914,m2:11.9246390512393,m3:11.8427345433492,hypo1:3.0,hypo2:0.0,hypo3:7.0,hyper1:0.0,hyper2:0.0,hyper3:5.0},
    {x:new Date(`2015-03-11`),m1:12.3526434635618,m2:12.0696,m3:11.4114398578112,hypo1:0.0,hypo2:10.0,hypo3:6.0,hyper1:2.0,hyper2:0.0,hyper3:9.0},
    {x:new Date(`2015-03-12`),m1:12.9333267870014,m2:11.9412,m3:12.5383934129626,hypo1:0.0,hypo2:12.0,hypo3:2.0,hyper1:9.0,hyper2:4.0,hyper3:4.0},
    {x:new Date(`2015-03-13`),m1:10.7855999999999,m2:11.9004673111641,m3:11.7741971466813,hypo1:2.0,hypo2:7.0,hypo3:9.0,hyper1:2.0,hyper2:3.0,hyper3:3.0},
    {x:new Date(`2015-03-16`),m1:10.5287999999999,m2:12.4616011987001,m3:13.0559164925397,hypo1:5.0,hypo2:7.0,hypo3:8.0,hyper1:0.0,hyper2:2.0,hyper3:2.0},
    {x:new Date(`2015-03-17`),m1:11.9044269256618,m2:12.5832,m3:11.957683693891,hypo1:4.0,hypo2:9.0,hypo3:0.0,hyper1:8.0,hyper2:6.0,hyper3:5.0},
    {x:new Date(`2015-03-18`),m1:11.9456908405958,m2:12.5815729519314,m3:12.2974583098857,hypo1:8.0,hypo2:2.0,hypo3:0.0,hyper1:5.0,hyper2:9.0,hyper3:5.0},
    {x:new Date(`2015-03-19`),m1:11.0423999999999,m2:11.2992,m3:11.0056937586484,hypo1:6.0,hypo2:6.0,hypo3:8.0,hyper1:0.0,hyper2:6.0,hyper3:8.0},
    {x:new Date(`2015-03-20`),m1:12.3985714280842,m2:11.6844,m3:12.6005814129809,hypo1:6.0,hypo2:8.0,hypo3:7.0,hyper1:3.0,hyper2:5.0,hyper3:6.0},
    {x:new Date(`2015-03-23`),m1:11.4276,m2:11.1820584233698,m3:12.4361657493192,hypo1:7.0,hypo2:3.0,hypo3:8.0,hyper1:3.0,hyper2:0.0,hyper3:4.0},
    {x:new Date(`2015-03-24`),m1:11.1708,m2:10.5287999999999,m3:11.6081191004604,hypo1:5.0,hypo2:3.0,hypo3:6.0,hyper1:5.0,hyper2:5.0,hyper3:9.0},
    {x:new Date(`2015-03-25`),m1:12.4288500079773,m2:12.1633822340033,m3:11.6700589153976,hypo1:0.0,hypo2:8.0,hypo3:11.0,hyper1:10.0,hyper2:1.0,hyper3:4.0},
    {x:new Date(`2015-03-26`),m1:11.9412,m2:12.4547999999999,m3:11.9170968553073,hypo1:5.0,hypo2:9.0,hypo3:0.0,hyper1:3.0,hyper2:9.0,hyper3:6.0},
    {x:new Date(`2015-03-27`),m1:11.1708,m2:12.340312463345,m3:12.4653886848352,hypo1:5.0,hypo2:8.0,hypo3:12.0,hyper1:5.0,hyper2:2.0,hyper3:6.0},
    {x:new Date(`2015-03-30`),m1:11.6844,m2:11.5721127303306,m3:12.5962112968756,hypo1:0.0,hypo2:0.0,hypo3:5.0,hyper1:3.0,hyper2:0.0,hyper3:2.0},
    {x:new Date(`2015-03-31`),m1:13.8672,m2:12.0696,m3:11.6619340894953,hypo1:5.0,hypo2:0.0,hypo3:0.0,hyper1:5.0,hyper2:0.0,hyper3:11.0},
    {x:new Date(`2015-04-01`),m1:12.84,m2:11.8403184558662,m3:12.0795746828889,hypo1:4.0,hypo2:0.0,hypo3:6.0,hyper1:4.0,hyper2:0.0,hyper3:1.0},
    {x:new Date(`2015-04-02`),m1:11.4276,m2:12.1979999999999,m3:12.103583353981,hypo1:6.0,hypo2:3.0,hypo3:0.0,hyper1:10.0,hyper2:5.0,hyper3:8.0},
    {x:new Date(`2015-04-03`),m1:11.556,m2:11.2073435651797,m3:11.1722557748225,hypo1:0.0,hypo2:10.0,hypo3:5.0,hyper1:0.0,hyper2:3.0,hyper3:3.0},
    {x:new Date(`2015-04-06`),m1:12.1979999999999,m2:12.5832,m3:11.5397114245826,hypo1:2.0,hypo2:5.0,hypo3:0.0,hyper1:7.0,hyper2:6.0,hyper3:0.0},
    {x:new Date(`2015-04-07`),m1:12.3264,m2:11.2226383996932,m3:12.4442066028336,hypo1:3.0,hypo2:5.0,hypo3:13.0,hyper1:12.0,hyper2:2.0,hyper3:10.0},
    {x:new Date(`2015-04-08`),m1:11.556,m2:11.6844,m3:11.8064589198031,hypo1:25.0,hypo2:5.0,hypo3:3.0,hyper1:10.0,hyper2:5.0,hyper3:7.0},
    {x:new Date(`2015-04-09`),m1:11.2992,m2:11.5093676532166,m3:11.6253981816118,hypo1:0.0,hypo2:3.0,hypo3:5.0,hyper1:4.0,hyper2:4.0,hyper3:3.0},
    {x:new Date(`2015-04-10`),m1:11.556,m2:11.8128,m3:11.8651736529977,hypo1:7.0,hypo2:4.0,hypo3:2.0,hyper1:19.0,hyper2:11.0,hyper3:17.0},
    {x:new Date(`2015-04-13`),m1:11.6844,m2:12.1979999999999,m3:11.5311804913114,hypo1:7.0,hypo2:14.0,hypo3:2.0,hyper1:1.0,hyper2:9.0,hyper3:5.0},
    {x:new Date(`2015-04-14`),m1:11.4276,m2:11.35369842187,m3:11.0467496086539,hypo1:9.0,hypo2:0.0,hypo3:2.0,hyper1:0.0,hyper2:3.0,hyper3:8.0},
    {x:new Date(`2015-04-15`),m1:12.4547999999999,m2:12.2280922952858,m3:11.8825511835142,hypo1:5.0,hypo2:0.0,hypo3:7.0,hyper1:7.0,hyper2:5.0,hyper3:0.0},
    {x:new Date(`2015-04-16`),m1:12.1979999999999,m2:11.651562836451,m3:11.8968463251423,hypo1:3.0,hypo2:8.0,hypo3:9.0,hyper1:0.0,hyper2:11.0,hyper3:4.0},
    {x:new Date(`2015-04-17`),m1:11.556,m2:12.1771674615501,m3:11.8269136022121,hypo1:11.0,hypo2:11.0,hypo3:7.0,hyper1:8.0,hyper2:6.0,hyper3:2.0},
    {x:new Date(`2015-04-20`),m1:12.4547999999999,m2:12.3888166347575,m3:13.0759611548955,hypo1:13.0,hypo2:7.0,hypo3:5.0,hyper1:13.0,hyper2:4.0,hyper3:5.0},
    {x:new Date(`2015-04-21`),m1:12.5832,m2:12.200053009372,m3:12.3662054870471,hypo1:0.0,hypo2:0.0,hypo3:11.0,hyper1:4.0,hyper2:4.0,hyper3:4.0},
    {x:new Date(`2015-04-22`),m1:10.914,m2:13.2814721265594,m3:12.5255335371479,hypo1:5.0,hypo2:0.0,hypo3:9.0,hyper1:2.0,hyper2:4.0,hyper3:10.0},
    {x:new Date(`2015-04-23`),m1:11.2992,m2:12.200053009372,m3:11.155366518197,hypo1:17.0,hypo2:2.0,hypo3:4.0,hyper1:2.0,hyper2:8.0,hyper3:13.0},
    {x:new Date(`2015-04-24`),m1:12.0696,m2:11.8570247188835,m3:12.4651284907985,hypo1:4.0,hypo2:5.0,hypo3:8.0,hyper1:17.0,hyper2:0.0,hyper3:4.0},
    {x:new Date(`2015-04-27`),m1:11.1708,m2:10.9093018755408,m3:12.4542741105585,hypo1:11.0,hypo2:8.0,hypo3:13.0,hyper1:11.0,hyper2:8.0,hyper3:0.0},
    {x:new Date(`2015-04-28`),m1:12.3264,m2:12.3385558150072,m3:11.7215933465906,hypo1:8.0,hypo2:0.0,hypo3:1.0,hyper1:0.0,hyper2:12.0,hyper3:4.0},
    {x:new Date(`2015-04-29`),m1:11.9412,m2:11.5963193535623,m3:11.6599325969058,hypo1:13.0,hypo2:7.0,hypo3:5.0,hyper1:0.0,hyper2:4.0,hyper3:4.0},
    {x:new Date(`2015-04-30`),m1:12.3264,m2:12.3225558832472,m3:12.2323330818546,hypo1:0.0,hypo2:0.0,hypo3:3.0,hyper1:0.0,hyper2:0.0,hyper3:0.0},
    {x:new Date(`2015-05-01`),m1:12.0696,m2:12.7565744045727,m3:11.1813767721793,hypo1:4.0,hypo2:0.0,hypo3:6.0,hyper1:8.0,hyper2:0.0,hyper3:5.0},
    {x:new Date(`2015-05-04`),m1:11.556,m2:11.1769094407197,m3:12.5085082693271,hypo1:5.0,hypo2:20.0,hypo3:13.0,hyper1:5.0,hyper2:0.0,hyper3:2.0},
    {x:new Date(`2015-05-05`),m1:13.0968,m2:11.5959865339374,m3:11.8040337729405,hypo1:3.0,hypo2:0.0,hypo3:5.0,hyper1:3.0,hyper2:5.0,hyper3:0.0},
    {x:new Date(`2015-05-06`),m1:11.8128,m2:12.9475682645579,m3:12.0179126088925,hypo1:4.0,hypo2:4.0,hypo3:15.0,hyper1:7.0,hyper2:0.0,hyper3:3.0},
    {x:new Date(`2015-05-07`),m1:11.8128,m2:12.0787848607978,m3:12.2855271266325,hypo1:9.0,hypo2:8.0,hypo3:6.0,hyper1:9.0,hyper2:0.0,hyper3:2.0},
    {x:new Date(`2015-05-08`),m1:11.6844,m2:12.4514497185873,m3:11.8877875696825,hypo1:6.0,hypo2:10.0,hypo3:0.0,hyper1:8.0,hyper2:2.0,hyper3:5.0},
    {x:new Date(`2015-05-11`),m1:11.556,m2:11.537488142544,m3:13.8211888996783,hypo1:2.0,hypo2:6.0,hypo3:6.0,hyper1:7.0,hyper2:2.0,hyper3:8.0},
    {x:new Date(`2015-05-12`),m1:11.8128,m2:12.4503150679158,m3:11.2870647305823,hypo1:4.0,hypo2:0.0,hypo3:12.0,hyper1:16.0,hyper2:4.0,hyper3:12.0},
    {x:new Date(`2015-05-13`),m1:11.1708,m2:11.3026374866382,m3:12.3322647475703,hypo1:5.0,hypo2:5.0,hypo3:0.0,hyper1:5.0,hyper2:8.0,hyper3:0.0},
    {x:new Date(`2015-05-14`),m1:11.556,m2:12.7629963365097,m3:12.7014452926891,hypo1:9.0,hypo2:3.0,hypo3:2.0,hyper1:0.0,hyper2:0.0,hyper3:8.0},
    {x:new Date(`2015-05-15`),m1:12.84,m2:12.4597586818078,m3:12.6620913735404,hypo1:0.0,hypo2:7.0,hypo3:0.0,hyper1:0.0,hyper2:3.0,hyper3:1.0},
    {x:new Date(`2015-05-18`),m1:11.1708,m2:11.950549320893,m3:13.016308579459,hypo1:0.0,hypo2:7.0,hypo3:5.0,hyper1:0.0,hyper2:7.0,hyper3:2.0},
    {x:new Date(`2015-05-19`),m1:11.6844,m2:12.6657545865334,m3:11.3989779930943,hypo1:5.0,hypo2:3.0,hypo3:6.0,hyper1:14.0,hyper2:1.0,hyper3:0.0},
    {x:new Date(`2015-05-20`),m1:12.0696,m2:12.8999716270709,m3:11.2317308945995,hypo1:0.0,hypo2:5.0,hypo3:0.0,hyper1:6.0,hyper2:5.0,hyper3:5.0},
    {x:new Date(`2015-05-21`),m1:11.6844,m2:12.4047942641715,m3:11.0138644232535,hypo1:0.0,hypo2:0.0,hypo3:9.0,hyper1:0.0,hyper2:7.0,hyper3:8.0},
    {x:new Date(`2015-05-22`),m1:12.5832,m2:12.8999716270709,m3:11.4163166374691,hypo1:0.0,hypo2:1.0,hypo3:0.0,hyper1:6.0,hyper2:5.0,hyper3:0.0},
    {x:new Date(`2015-05-25`),m1:11.4276,m2:10.9557102885855,m3:11.2348749535301,hypo1:15.0,hypo2:12.0,hypo3:0.0,hyper1:3.0,hyper2:3.0,hyper3:4.0},
    {x:new Date(`2015-05-26`),m1:12.0696,m2:11.0835849879692,m3:12.2956678318007,hypo1:0.0,hypo2:13.0,hypo3:0.0,hyper1:4.0,hyper2:6.0,hyper3:18.0},
    {x:new Date(`2015-05-27`),m1:11.8128,m2:11.8348253067915,m3:11.256048548007,hypo1:4.0,hypo2:8.0,hypo3:8.0,hyper1:20.0,hyper2:5.0,hyper3:4.0},
    {x:new Date(`2015-05-28`),m1:12.0696,m2:12.4304888541798,m3:11.0242721847186,hypo1:11.0,hypo2:0.0,hypo3:4.0,hyper1:5.0,hyper2:1.0,hyper3:5.0},
    {x:new Date(`2015-05-29`),m1:11.556,m2:11.3041140163142,m3:11.770295951697,hypo1:17.0,hypo2:8.0,hypo3:3.0,hyper1:25.0,hyper2:8.0,hyper3:4.0},
    {x:new Date(`2015-06-01`),m1:12.1979999999999,m2:12.0839322181836,m3:12.7181102918438,hypo1:0.0,hypo2:14.0,hypo3:7.0,hyper1:0.0,hyper2:0.0,hyper3:11.0},
    {x:new Date(`2015-06-02`),m1:11.2992,m2:11.3481515184229,m3:12.1177018827069,hypo1:0.0,hypo2:2.0,hypo3:4.0,hyper1:0.0,hyper2:4.0,hyper3:10.0},
    {x:new Date(`2015-06-03`),m1:11.8128,m2:12.0944954339149,m3:12.4871471967025,hypo1:8.0,hypo2:2.0,hypo3:10.0,hyper1:3.0,hyper2:4.0,hyper3:10.0},
    {x:new Date(`2015-06-04`),m1:11.556,m2:11.7246310404863,m3:10.4004,hypo1:9.0,hypo2:1.0,hypo3:3.0,hyper1:0.0,hyper2:0.0,hyper3:3.0},
    {x:new Date(`2015-06-05`),m1:11.6844,m2:12.728184089974,m3:12.5832,hypo1:0.0,hypo2:0.0,hypo3:0.0,hyper1:4.0,hyper2:7.0,hyper3:0.0},
    {x:new Date(`2015-06-08`),m1:11.6844,m2:11.2576462206477,m3:12.3264,hypo1:2.0,hypo2:2.0,hypo3:1.0,hyper1:0.0,hyper2:8.0,hyper3:10.0},
    {x:new Date(`2015-06-09`),m1:11.9412,m2:13.3294073022215,m3:9.8868,hypo1:6.0,hypo2:10.0,hypo3:10.0,hyper1:12.0,hyper2:3.0,hyper3:10.0},
    {x:new Date(`2015-06-10`),m1:11.2992,m2:12.0276490125576,m3:9.8868,hypo1:5.0,hypo2:3.0,hypo3:10.0,hyper1:0.0,hyper2:3.0,hyper3:1.0},
    {x:new Date(`2015-06-11`),m1:11.6844,m2:12.8718432220738,m3:12.0696,hypo1:8.0,hypo2:8.0,hypo3:3.0,hyper1:11.0,hyper2:1.0,hyper3:10.0},
    {x:new Date(`2015-06-12`),m1:11.2992,m2:12.5405366352976,m3:13.9956,hypo1:9.0,hypo2:8.0,hypo3:6.0,hyper1:12.0,hyper2:3.0,hyper3:9.0},
    {x:new Date(`2015-06-15`),m1:11.556,m2:11.7971057493059,m3:14.5091999999999,hypo1:2.0,hypo2:4.0,hypo3:0.0,hyper1:2.0,hyper2:4.0,hyper3:5.0},
    {x:new Date(`2015-06-16`),m1:12.0696,m2:11.7240900656322,m3:9.7584,hypo1:5.0,hypo2:14.0,hypo3:5.0,hyper1:5.0,hyper2:12.0,hyper3:0.0},
    {x:new Date(`2015-06-17`),m1:11.1708,m2:12.3181217193791,m3:12.0696,hypo1:2.0,hypo2:8.0,hypo3:0.0,hyper1:5.0,hyper2:4.0,hyper3:8.0},
    {x:new Date(`2015-06-18`),m1:11.4276,m2:11.7790922257001,m3:10.0152,hypo1:0.0,hypo2:0.0,hypo3:6.0,hyper1:5.0,hyper2:13.0,hyper3:0.0},
    {x:new Date(`2015-06-19`),m1:11.4276,m2:12.033906340548,m3:12.7115999999999,hypo1:3.0,hypo2:11.0,hypo3:5.0,hyper1:6.0,hyper2:1.0,hyper3:5.0},
    {x:new Date(`2015-06-22`),m1:11.8128,m2:12.3981619798859,m3:11.556,hypo1:6.0,hypo2:0.0,hypo3:5.0,hyper1:0.0,hyper2:0.0,hyper3:15.0},
    {x:new Date(`2015-06-23`),m1:11.2992,m2:12.5511628453825,m3:10.0152,hypo1:0.0,hypo2:10.0,hypo3:6.0,hyper1:9.0,hyper2:1.0,hyper3:1.0},
    {x:new Date(`2015-06-24`),m1:11.1708,m2:12.6081700194708,m3:12.83485211759,hypo1:0.0,hypo2:7.0,hypo3:6.0,hyper1:0.0,hyper2:4.0,hyper3:6.0},
    {x:new Date(`2015-06-25`),m1:11.6844,m2:11.7894794003843,m3:10.272,hypo1:14.0,hypo2:7.0,hypo3:3.0,hyper1:0.0,hyper2:7.0,hyper3:3.0},
    {x:new Date(`2015-06-26`),m1:11.6844,m2:12.2282844385744,m3:11.8128,hypo1:5.0,hypo2:5.0,hypo3:5.0,hyper1:5.0,hyper2:7.0,hyper3:0.0},
    {x:new Date(`2015-06-29`),m1:11.2992,m2:12.2738137298433,m3:11.2100770322019,hypo1:0.0,hypo2:0.0,hypo3:3.0,hyper1:0.0,hyper2:3.0,hyper3:9.0},
    {x:new Date(`2015-06-30`),m1:11.9412,m2:11.857124221658,m3:12.3917834186301,hypo1:6.0,hypo2:0.0,hypo3:8.0,hyper1:9.0,hyper2:9.0,hyper3:4.0},
    {x:new Date(`2015-07-01`),m1:11.8128,m2:11.5002488529791,m3:11.8128,hypo1:5.0,hypo2:5.0,hypo3:12.0,hyper1:5.0,hyper2:0.0,hyper3:5.0},
    {x:new Date(`2015-07-02`),m1:11.6844,m2:12.4198912368462,m3:12.1832610584016,hypo1:5.0,hypo2:0.0,hypo3:2.0,hyper1:3.0,hyper2:4.0,hyper3:3.0},
    {x:new Date(`2015-07-03`),m1:11.4276,m2:13.2976533363625,m3:11.9412,hypo1:3.0,hypo2:6.0,hypo3:5.0,hyper1:6.0,hyper2:12.0,hyper3:2.0},
    {x:new Date(`2015-07-06`),m1:11.6844,m2:12.1507534779197,m3:11.3534799732503,hypo1:5.0,hypo2:8.0,hypo3:6.0,hyper1:5.0,hyper2:2.0,hyper3:6.0},
    {x:new Date(`2015-07-07`),m1:12.1979999999999,m2:12.4811597842842,m3:12.1979999999999,hypo1:13.0,hypo2:7.0,hypo3:7.0,hyper1:0.0,hyper2:2.0,hyper3:2.0},
    {x:new Date(`2015-07-08`),m1:11.556,m2:11.5691659613839,m3:12.1979999999999,hypo1:15.0,hypo2:6.0,hypo3:0.0,hyper1:4.0,hyper2:5.0,hyper3:5.0},
    {x:new Date(`2015-07-09`),m1:12.1979999999999,m2:12.1771285754084,m3:12.0885492853591,hypo1:4.0,hypo2:4.0,hypo3:9.0,hyper1:4.0,hyper2:4.0,hyper3:7.0},
    {x:new Date(`2015-07-10`),m1:11.6844,m2:12.2402841541299,m3:12.5832,hypo1:12.0,hypo2:0.0,hypo3:13.0,hyper1:0.0,hyper2:0.0,hyper3:3.0},
    {x:new Date(`2015-07-13`),m1:11.9412,m2:12.0571728341178,m3:11.9962473093943,hypo1:9.0,hypo2:0.0,hypo3:0.0,hyper1:0.0,hyper2:2.0,hyper3:9.0},
    {x:new Date(`2015-07-14`),m1:11.6844,m2:11.9090555202387,m3:11.8128,hypo1:0.0,hypo2:10.0,hypo3:0.0,hyper1:20.0,hyper2:3.0,hyper3:6.0},
    {x:new Date(`2015-07-15`),m1:11.9412,m2:11.6560873534132,m3:11.4713816112891,hypo1:13.0,hypo2:11.0,hypo3:0.0,hyper1:3.0,hyper2:0.0,hyper3:6.0},
    {x:new Date(`2015-07-16`),m1:11.8128,m2:11.5151062183981,m3:12.3264,hypo1:12.0,hypo2:3.0,hypo3:0.0,hyper1:4.0,hyper2:3.0,hyper3:5.0},
    {x:new Date(`2015-07-17`),m1:11.6844,m2:11.908682670762,m3:11.1004021005607,hypo1:0.0,hypo2:4.0,hypo3:5.0,hyper1:4.0,hyper2:0.0,hyper3:8.0},
    {x:new Date(`2015-07-20`),m1:11.6844,m2:12.894196944722,m3:12.2748956795516,hypo1:4.0,hypo2:1.0,hypo3:7.0,hyper1:4.0,hyper2:4.0,hyper3:0.0},
    {x:new Date(`2015-07-21`),m1:11.8128,m2:11.5980590268508,m3:11.9412,hypo1:5.0,hypo2:4.0,hypo3:0.0,hyper1:1.0,hyper2:4.0,hyper3:7.0},
    {x:new Date(`2015-07-22`),m1:12.1979999999999,m2:11.2990908417697,m3:11.6844,hypo1:4.0,hypo2:0.0,hypo3:0.0,hyper1:11.0,hyper2:10.0,hyper3:4.0},
    {x:new Date(`2015-07-23`),m1:11.9412,m2:12.8541957759852,m3:11.7753219855166,hypo1:2.0,hypo2:3.0,hypo3:2.0,hyper1:8.0,hyper2:6.0,hyper3:5.0},
    {x:new Date(`2015-07-24`),m1:12.0696,m2:12.5882340095699,m3:11.8128,hypo1:6.0,hypo2:2.0,hypo3:6.0,hyper1:3.0,hyper2:9.0,hyper3:16.0},
    {x:new Date(`2015-07-27`),m1:11.9412,m2:10.8045746335807,m3:11.0515039210355,hypo1:5.0,hypo2:13.0,hypo3:1.0,hyper1:5.0,hyper2:6.0,hyper3:11.0},
    {x:new Date(`2015-07-28`),m1:11.8128,m2:13.3268042181452,m3:11.8128,hypo1:15.0,hypo2:8.0,hypo3:5.0,hyper1:6.0,hyper2:6.0,hyper3:5.0},
    {x:new Date(`2015-07-29`),m1:11.6844,m2:12.6107558575956,m3:11.5670460948042,hypo1:0.0,hypo2:0.0,hypo3:6.0,hyper1:4.0,hyper2:4.0,hyper3:1.0},
    {x:new Date(`2015-07-30`),m1:11.8128,m2:11.664853890955,m3:11.9412,hypo1:5.0,hypo2:8.0,hypo3:7.0,hyper1:0.0,hyper2:4.0,hyper3:1.0},
    {x:new Date(`2015-07-31`),m1:11.4276,m2:11.0968441088925,m3:12.0619054160085,hypo1:2.0,hypo2:7.0,hypo3:5.0,hyper1:14.0,hyper2:9.0,hyper3:7.0},
    {x:new Date(`2015-08-03`),m1:11.4276,m2:11.9371472362133,m3:12.0696,hypo1:5.0,hypo2:3.0,hypo3:5.0,hyper1:5.0,hyper2:3.0,hyper3:2.0},
    {x:new Date(`2015-08-04`),m1:11.4276,m2:12.7246122834844,m3:12.1904206833216,hypo1:14.0,hypo2:2.0,hypo3:1.0,hyper1:0.0,hyper2:10.0,hyper3:6.0},
    {x:new Date(`2015-08-05`),m1:11.2992,m2:12.199157484402,m3:12.3231036300609,hypo1:0.0,hypo2:7.0,hypo3:8.0,hyper1:5.0,hyper2:2.0,hyper3:0.0},
    {x:new Date(`2015-08-06`),m1:12.0696,m2:12.9683997994288,m3:11.9412,hypo1:0.0,hypo2:10.0,hypo3:5.0,hyper1:0.0,hyper2:2.0,hyper3:3.0},
    {x:new Date(`2015-08-07`),m1:11.8128,m2:11.3801581539025,m3:12.4117937696843,hypo1:4.0,hypo2:9.0,hypo3:2.0,hyper1:0.0,hyper2:6.0,hyper3:9.0},
    {x:new Date(`2015-08-10`),m1:11.6844,m2:12.1168041606176,m3:11.4276,hypo1:4.0,hypo2:4.0,hypo3:4.0,hyper1:0.0,hyper2:6.0,hyper3:8.0},
    {x:new Date(`2015-08-11`),m1:12.1979999999999,m2:11.3419593819055,m3:11.9246390512393,hypo1:4.0,hypo2:2.0,hypo3:5.0,hyper1:6.0,hyper2:11.0,hyper3:6.0},
    {x:new Date(`2015-08-12`),m1:11.8128,m2:12.0324212330467,m3:12.0696,hypo1:12.0,hypo2:5.0,hypo3:6.0,hyper1:0.0,hyper2:0.0,hyper3:3.0},
    {x:new Date(`2015-08-13`),m1:11.556,m2:11.5605006421817,m3:11.9412,hypo1:5.0,hypo2:2.0,hypo3:4.0,hyper1:2.0,hyper2:2.0,hyper3:4.0},
    {x:new Date(`2015-08-14`),m1:11.2992,m2:12.4527323894096,m3:11.9004673111641,hypo1:7.0,hypo2:7.0,hypo3:1.0,hyper1:14.0,hyper2:2.0,hyper3:4.0},
    {x:new Date(`2015-08-17`),m1:11.0423999999999,m2:12.7494365101437,m3:12.4616011987001,hypo1:0.0,hypo2:2.0,hypo3:1.0,hyper1:0.0,hyper2:5.0,hyper3:6.0},
    {x:new Date(`2015-08-18`),m1:12.1979999999999,m2:11.9450022368443,m3:12.5832,hypo1:9.0,hypo2:2.0,hypo3:0.0,hyper1:9.0,hyper2:2.0,hyper3:8.0},
    {x:new Date(`2015-08-19`),m1:12.7115999999999,m2:12.092736498157,m3:12.5815729519314,hypo1:3.0,hypo2:7.0,hypo3:3.0,hyper1:0.0,hyper2:9.0,hyper3:14.0},
    {x:new Date(`2015-08-20`),m1:10.1436,m2:11.3109316719289,m3:11.2992,hypo1:3.0,hypo2:0.0,hypo3:2.0,hyper1:3.0,hyper2:6.0,hyper3:6.0},
    {x:new Date(`2015-08-21`),m1:10.4004,m2:12.9837232266936,m3:11.6844,hypo1:3.0,hypo2:5.0,hypo3:4.0,hyper1:2.0,hyper2:5.0,hyper3:0.0},
    {x:new Date(`2015-08-24`),m1:12.0696,m2:12.5911446613492,m3:11.1820584233698,hypo1:4.0,hypo2:8.0,hypo3:4.0,hyper1:17.0,hyper2:12.0,hyper3:2.0},
    {x:new Date(`2015-08-25`),m1:11.6844,m2:12.0101262308646,m3:10.5287999999999,hypo1:0.0,hypo2:12.0,hypo3:5.0,hyper1:9.0,hyper2:2.0,hyper3:1.0},
    {x:new Date(`2015-08-26`),m1:10.0152,m2:12.1973001895798,m3:12.1633822340033,hypo1:3.0,hypo2:0.0,hypo3:8.0,hyper1:0.0,hyper2:8.0,hyper3:5.0},
    
]