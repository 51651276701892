import React, { Component } from "react";
import { VictoryBoxPlot } from "victory";
import {
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryZoomContainer,
  VictoryScatter,
  VictoryLine,
  VictoryBrushContainer,
  VictoryAxis,
} from "victory";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import * as V from "victory";
import { IconButton, MenuItem, TextField } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import AnalytesParameterButton from "../analytes/AnalytesParameterButton";
import Button from "@material-ui/core/Button";
import { FilterList, Settings } from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { params, ft4 } from "./Data";
import c3 from "c3";
import { sum } from "d3";

class LineChart2 extends Component {
  constructor(props) {
    super();
    this.handleChangeBand = this.handleChangeBand.bind(this);
  }
  state = {
    param: "FT4",
    date: "1/1/2022",
    labs: ["Demo 1", "DEMOLAB"],
    dataa: ["Median", "Hypo", "Hyper"],
    view: ["Long-term m.", "1.st,3.de q.", "Min,max"],
    groups: ["All", "Cobas"],
    instruments: ["OUS, MBK 1.", "OUS, MBK 2."],
    bandwidth: ["5 days", "11 days"],
    band:"11 days"
  };
  handleChangeBand(param) {
    let v = { ...this.state.val, param: param.target.value };
    this.setState({
        band: this.state.band ==='5 days' ? '11 days' : '5 days'
    });
}
  smooth = (x, ft4, value) => {
    
    let m1 = ft4.map((i, index) => [index, i[value]]);
    let band = this.state.band ==='5 days' ? 5 : 11;
    console.log('band',band);
    let sd1 = smoothedNumbers(x, m1, band );
    let sm1 = sd1.map((i, index) => ({ x: ft4[index]["x"], y: i[1] }));
    return sm1;
  };
  render() {
    const { classes, labs } = this.props;
    let x = ft4.map((i, index) => index);
    // let m1 = ft4.map((i,index) => i.m1);
    // let m1 = ft4.map((i,index) => [index, i.m1]);
    // let sd1 =smoothedNumbers(x, m1, 5);
    // let sm1 = sd1.map((i,index) => ({x:ft4[index]['x'], m1: i[1]}));
    let sm1 = this.smooth(x, ft4, "m1");
    let shypo1 = this.smooth(x, ft4, "hypo1");
    let shyper1 = this.smooth(x, ft4, "hyper1");

    let sm2 = this.smooth(x, ft4, "m2");
    let shypo2 = this.smooth(x, ft4, "hypo2");
    let shyper2 = this.smooth(x, ft4, "hyper2");

    let sm3 = this.smooth(x, ft4, "m3");
    let shypo3 = this.smooth(x, ft4, "hypo3");
    let shyper3 = this.smooth(x, ft4, "hyper3");

    // let data5  = [];
    //     ft4.forEach((q, index) => (
    //       // data2[index] = f_hat( q )
    //       data5.push(
    //         {
    //           x: q.x,
    //           y: y[index],
    //           // amt: 2100,
    //         }
    //       )

    // ));
    return (
      <div>
        <TextField
          // required
          id={"param"}
          value={this.state.param ?? ""}
          name={"param"}
          select
          label={"Analyte"}
          //  InputProps={{ disableUnderline: true }}
          className={classes.selectfilter2}
          // onChange={this.handleChangeParam}
          //  helperText={"Select lab"}
        >
          {params.map((option1) => (
            <MenuItem key={option1} value={option1}>
              {option1}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          // required
          id={"data"}
          value={"Median"}
          name={"data"}
          select
          label={"Measurement"}
          SelectProps={{
            multiple: true,
            value: ["Median", "Hyper"],
          }}
          //  InputProps={{ disableUnderline: true }}
          className={classes.selectfilter2}
          // onChange={this.handleChangeParam}
          //  helperText={"Select lab"}
        >
          {this.state.dataa.map((option1) => (
            <MenuItem key={option1} value={option1}>
              {option1}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          // required
          id={"groupid"}
          value={["OUS, MBK 1.", "OUS, MBK 2."]}
          name={"groupid"}
          select
          className={classes.selectfilter2}
          label={"Instruments"}
          SelectProps={{
            multiple: true,
            value: ["OUS, MBK 1.", "OUS, MBK 2."],
          }}
          // onChange={this.handleChangeLab}
          // InputProps={{ disableUnderline: true }}
          //  helperText={"Select lab"}
        >
          {this.state.instruments.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          // required
          id={"groupid"}
          value={["All"]}
          name={"groupid"}
          select
          className={classes.selectfilter2}
          label={"Group"}
          SelectProps={{
            multiple: true,
            value: ["All"],
          }}
          // onChange={this.handleChangeLab}
          // InputProps={{ disableUnderline: true }}
          //  helperText={"Select lab"}
        >
          {this.state.groups.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            // required
            // className={classes.datetime}
            className={classes.selectfilter3}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date"
            autoOk={true}
            name="date"
            label="From"
            value={"01/01/2015"}
            // onChange={this.handleChangeDate}
            // helperText={"Select date"}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            // required
            // className={classes.datetime}
            className={classes.selectfilter3}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date"
            autoOk={true}
            name="date"
            label="To"
            value={"08/01/2015"}
            // value={this.state.date}
            // onChange={this.handleChangeDate}
            // helperText={"Select date"}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
        <TextField
          // required
          id={"bandwidth"}
          value={this.state.band}
          name={"bandwidth"}
          select
          label={"Bandwidth"}
          //  InputProps={{ disableUnderline: true }}
          className={classes.selectfilter2}
          onChange={this.handleChangeBand}
          //  helperText={"Select lab"}
        >
          {this.state.bandwidth.map((option1) => (
            <MenuItem key={option1} value={option1}>
              {option1}
            </MenuItem>
          ))}
        </TextField>
        <IconButton
          color="primary"
          className={classes.button2}
          aria-label="upload picture"
          component="span"
        >
          <Settings />
        </IconButton>

        <Button
          // variant={labDeviceParameter && labDeviceParameter.includes(labDevice + measure) ? "contained" : "outlined"}
          color="primary"
          variant="outlined"
          // size="small"
          className={classes.button2}
          //  endIcon={<Icon>send</Icon>}
          // onClick={() => this.handleClick(measure)}
        >
          {" "}
          Filter
        </Button>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <VictoryChart scale={{ x: "time" }}>
                <VictoryLabel text="FT4" x={225} y={30} textAnchor="middle" />
                <VictoryAxis
                  crossAxis
                  //   label="Date"
                  scale="time"
                  //   tickFormat=
                  style={{
                    axisLabel: { fontSize: 8, padding: 25 },
                    tickLabels: { fontSize: 6, padding: 5 },
                  }}
                  tickFormat={(x) => {
                    return (
                      x.getMonth().toString() + "/" + x.getFullYear().toString()
                    );
                    // if (x.getFullYear() === 2000) {
                    //   return x.getFullYear();
                    // }
                    // if (x.getFullYear() % 5 === 0) {
                    //   return x.getFullYear().toString().slice(2);
                    // }
                  }}
                />
                <VictoryAxis
                  dependentAxis
                  crossAxis
                  label="FT4(pmol/L)"
                  style={{
                    axisLabel: { fontSize: 8, padding: 25 },
                    tickLabels: { fontSize: 6, padding: 5 },
                  }}
                />
                <VictoryLine
                  y={(d) => d.m1}
                  data={ft4}
                  style={{
                    data: {
                      stroke: "#FFEBEE",
                    },
                  }}
                />

                <VictoryLine
                  y={(d) => d.m2}
                  data={ft4}
                  style={{
                    data: {
                      stroke: "#E8F5E9",
                    },
                  }}
                />

                <VictoryLine
                  y={(d) => d.m3}
                  data={ft4}
                  style={{
                    data: {
                      stroke: "#F3E5F5",
                    },
                  }}
                />

                <VictoryLine
                  y={(d) => d.y}
                  data={sm1}
                  style={{
                    data: {
                      stroke: "#EF5350",
                    },
                  }}
                />

                <VictoryLine
                  y={(d) => d.y}
                  data={sm2}
                  style={{
                    data: {
                      stroke: "#3498DB", //#E8F5E9
                    },
                  }}
                />
                <VictoryLine
                  y={(d) => d.y}
                  data={sm3}
                  style={{
                    data: {
                      stroke: "#000000", //#F3E5F5
                      strokeDasharray: "2,2",
                      strokeWidth: 1,
                    },
                  }}
                />
                {/* <VictoryLine
                  y={(d) => d.m2}
                  data={ft4}
                />
                <VictoryLine
                  y={(d) => d.m3}
                  data={ft4}
                /> */}

                <VictoryLine
                  style={{
                    data: {
                      strokeDasharray: "2,2",
                      stroke: "#ccc",
                      strokeWidth: 1,
                      border: "0px dotted #ccc",
                    },
                    // parent: { border: "0px dotted #ccc" },
                  }}
                  data={[
                    { x: new Date(`2015-01-01`), y: 12.34 },
                    { x: new Date(`2015-08-26`), y: 12.34 },
                  ]}
                />
                <VictoryLine
                  style={{
                    data: {
                      strokeDasharray: "2,2",
                      stroke: "#ccc",
                      strokeWidth: 1,
                    },
                    // parent: { border: "0px dotted #ccc" },
                  }}
                  data={[
                    { x: new Date(`2015-01-01`), y: 11.3 },
                    { x: new Date(`2015-08-26`), y: 11.3 },
                  ]}
                />
                <VictoryScatter
                //   domain={[-10, 10]}
                  data={[{ x: new Date(`2015-03-27`), y: 11.1708 }]}
                  labels="New reagent bottle"
                  style={{ data: { fill: "#EF5350" } }}
                  size={1}
                  
                  labelComponent={
                  <VictoryLabel 
                //   angle={-45} 
                  textAnchor="middle" 
                  style={[
                    { fill: "#EF5350", fontSize: 5 },
                  ]}    
                  />
                }
                />
                <VictoryScatter
                //   domain={[-10, 10]}
                  data={[{ x: new Date(`2015-07-07`), y: 12.4811597842842 }]}
                  labels="New sample tubes in use"
                  style={{ data: { fill: "#3498db" } }}
                  size={1}
                  
                  labelComponent={
                  <VictoryLabel 
                //   angle={-45} 
                  textAnchor="middle" 
                  style={[
                    { fill: "#3498db", fontSize: 5 },
                  ]}    
                  />
                }
                />
              </VictoryChart>

              <V.VictoryLegend
                x={170}
                y={0}
                orientation="horizontal"
                gutter={20}
                fontSize={6}
                //   style={{ border: { stroke: "black" } }}
                colorScale={["#EF5350", "#3498DB", "#000000"]}
                style={{
                  data: { strokeWidth: 2 },
                  labels: { fontSize: 6 },
                  // border: { stroke: "black" },
                  title: { fontSize: 20 },
                }}
                data={[
                  {
                    name: "OUS, MBK 1.",
                    fontSize: 6,
                    symbol: { fill: "#EF5350", type: "line" },
                  },
                  {
                    name: "OUS, MBK 2.",
                    symbol: { fill: "#3498db" },
                    labels: { fill: "#3498db" },
                  },
                  { name: "Peer/All", symbol: { fill: "#00000" } },
                ]}
                //   data={[{ name: "One" }, { name: "Two" }, { name: "Three" }]}
              />
              {/* <V.VictoryLegend
                  data={[{ name: "One" }, { name: "Two" }, { name: "Three" }]}
                  labelComponent={<VictoryLabel angle={45} />}
                /> */}
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function smoothedNumbers(targets, sources, bandwidth) {
  console.log("y", targets);
  console.log("y2", sources);
  console.log("y3", bandwidth);
  console.log("y3", bandwidth);
  return targets.map(function (t) {
    var numerator = sum(sources, function (s) {
      return gaussian(s[0], t, bandwidth) * s[1];
    });

    var denominator = sum(sources, function (s) {
      return gaussian(s[0], t, bandwidth);
    });

    return [t, numerator / denominator];
  });
}

function gaussian(target, source, bandwidth) {
  return Math.exp(-Math.pow(target - source, 2) / (2 * bandwidth * bandwidth));
}

export default LineChart2;
