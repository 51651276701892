import React, { Component } from "react";
import { VictoryBoxPlot } from "victory";
import {
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryZoomContainer,
  VictoryScatter,
  VictoryLine,
  VictoryBrushContainer,
  VictoryAxis,
} from "victory";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import * as V from "victory";
import { IconButton, MenuItem, TextField } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import AnalytesParameterButton from "../analytes/AnalytesParameterButton";
import Button from "@material-ui/core/Button";
import { FilterList, Settings } from "@material-ui/icons";


class BoxPlot1 extends Component {
  state = {
    param: "FT4",
    date: "1/1/2022",
    labs: ["Demo 1", "DEMOLAB"],
    dataa: ["Median", "Hypo", "Hyper"],
    view: ["Long-term m.", "1.st,3.de q.", "Min,max"],
    groups: ["Alinity","Cobas"],
    params: [
      "Albumin",
      "Alkaline Phosphatase",
      "ALT - GPT",
      "AST - GOT",
      "Calcium",
      "Chloride",
      "Creatinine",
      "FT4",
      "GGT",
      "Glucose",
      "Inorganic phosphor",
      "LDH",
      "Magnesium",
      "Potassium",
      "Sodium",
      "total-Bilirubin",
      "total-Cholesterol",
      "total-Protein",
      "TSH",
      "Urea",
      "Uric acid",
    ],
  };
  render() {
    const { classes, labs } = this.props;
    return (
      <div>
         <TextField
          // required
          id={"param"}
          value={this.state.param ?? ""}
          name={"param"}
          select
          label={"Analyte"}
          //  InputProps={{ disableUnderline: true }}
          className={classes.selectfilter2}
          // onChange={this.handleChangeParam}
          //  helperText={"Select lab"}
        >
          {this.state.params.map((option1) => (
            <MenuItem key={option1} value={option1}>
              {option1}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          // required
          id={"data"}
          value={"Median"}
          name={"data"}
          select
          label={"Measurement"}
          //  InputProps={{ disableUnderline: true }}
          className={classes.selectfilter2}
          // onChange={this.handleChangeParam}
          //  helperText={"Select lab"}
        >
          {this.state.dataa.map((option1) => (
            <MenuItem key={option1} value={option1}>
              {option1}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          // required
          id={"groupid"}
          value={["Alinity"]}
          name={"groupid"}
          select
          className={classes.selectfilter2}
          label={"Group"}
          SelectProps={{
            multiple: true,
            value: ["Alinity"],
          }}
          // onChange={this.handleChangeLab}
          // InputProps={{ disableUnderline: true }}
          //  helperText={"Select lab"}
        >
          {this.state.groups.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            // required
            // className={classes.datetime}
            className={classes.selectfilter3}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date"
            autoOk={true}
            name="date"
            label="From"
            value={this.state.date}
            // onChange={this.handleChangeDate}
            // helperText={"Select date"}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            // required
            // className={classes.datetime}
            className={classes.selectfilter3}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date"
            autoOk={true}
            name="date"
            label="To"
            value={this.state.date}
            // onChange={this.handleChangeDate}
            // helperText={"Select date"}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
        <TextField
          // required
          id={"viewid"}
          value={this.state.view}
          name={"viewid"}
          select
          className={classes.selectfilter2}
          label={"Optional"}
          SelectProps={{
            multiple: true,
            value: this.state.view,
          }}
          // onChange={this.handleChangeLab}
          // InputProps={{ disableUnderline: true }}
          //  helperText={"Select lab"}
        >
          {this.state.view.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <IconButton
          color="primary"
          className={classes.button2}
          aria-label="upload picture"
          component="span"
        >
          <FilterList />
        </IconButton>
        <IconButton
          color="primary"
          className={classes.button2}
          aria-label="upload picture"
          component="span"
        >
          <Settings />
        </IconButton>

        <Button
          // variant={labDeviceParameter && labDeviceParameter.includes(labDevice + measure) ? "contained" : "outlined"}
          color="primary"
          variant="outlined"
          // size="small"
          className={classes.button2}
          //  endIcon={<Icon>send</Icon>}
          // onClick={() => this.handleClick(measure)}
        >
          {" "}
          Filter
        </Button>
        <VictoryChart
          domainPadding={20}
          // containerComponent={
          //   <VictoryVoronoiContainer
          //     mouseFollowTooltips
          //     voronoiDimension="x"
          //     labels={({ datum }) => `y: ${datum.median}`}
          //     labelComponent={
          //       <VictoryTooltip
          //         cornerRadius={0}
          //         flyoutStyle={{ fill: "white" }}
          //       />
          //     }
          //   />
          // }
        >
          <VictoryLabel text="FT4" x={225} y={30} textAnchor="middle"/>
          <VictoryAxis
            crossAxis
            label="Laboratory"
            style={{
              axisLabel: { fontSize: 8, padding: 25 },
              tickLabels: { fontSize: 6, padding: 5 },
            }}
          />
          <VictoryAxis
            dependentAxis
            crossAxis
            label="pmol/L"
            style={{
              axisLabel: { fontSize: 8, padding: 25 },
              tickLabels: { fontSize: 6, padding: 5 },
            }}
          />

          <VictoryBoxPlot
            labelComponent={<VictoryTooltip/>}
            minLabels
            maxLabels
            data={[
              //   { x: "Alinity Lab 1", y: [1, 2, 3, 5] },
              //   { x: "Alinity Lab 2", y: [3, 2, 8, 10] },
              //   { x: "Alinity Lab 3", y: [2, 8, 6, 5] },
              //   { x: "Alinity Lab 4", y: [1, 3, 2, 9] },
              {
                x: "Lab1",
                min: 11,
                median: 11.96,
                max: 13.05,
                q1: 11.7,
                q3: 12.23,
              },
              {
                x: "Lab2",
                min: 10.5,
                median: 11.92,
                max: 12.95,
                q1: 11.63,
                q3: 12.23,
              },
              {
                x: "Lab3",
                min: 10.42,
                median: 11.84,
                max: 15.73,
                q1: 11.46,
                q3: 12.29,
              },
              {
                x: "Lab4",
                min: 10.26,
                median: 11.63,
                max: 12.97,
                q1: 11.39,
                q3: 11.92,
              },
              {
                x: "Lab5",
                min: 10.42,
                median: 12.04,
                max: 13.52,
                q1: 11.81,
                q3: 12.41,
              },
              {
                x: "Lab6",
                min: 10.3,
                median: 11.6,
                max: 14.2,
                q1: 11.13,
                q3: 12.5,
              },
              {
                x: "Lab7",
                min: 10.6,
                median: 12.24,
                max: 14.46,
                q1: 11.76,
                q3: 12.68,
              },
              {
                x: "Lab8",
                min: 8.04,
                median: 12.28,
                max: 21.82,
                q1: 11.4,
                q3: 13.24,
              },
              {
                x: "Lab9",
                min: 10.05,
                median: 11.76,
                max: 12.75,
                q1: 11.48,
                q3: 12.03,
              },
              {
                x: "Lab10",
                min: 10.55,
                median: 12,
                max: 12.58,
                q1: 11.83,
                q3: 12.14,
              },
              {
                x: "Lab11",
                min: 6.71,
                median: 11.5,
                max: 15.3,
                q1: 11.29,
                q3: 11.8,
              },
              {
                x: "Lab12",
                min: 10.57,
                median: 11.73,
                max: 12.9,
                q1: 11.47,
                q3: 12.06,
              },
              {
                x: "Lab13",
                min: 10.2,
                median: 11.57,
                max: 12.41,
                q1: 11.37,
                q3: 11.78,
              },
              {
                x: "Lab14",
                min: 9.51,
                median: 12.32,
                max: 13.72,
                q1: 11.7,
                q3: 12.78,
              },
            ]}
            style={{
              min: { stroke: "tomato" },
              max: { stroke: "orange" },
              q1: { fill: "tomato" },
              q3: { fill: "orange" },
              median: { stroke: "white", strokeWidth: 2 },
              minLabels: { fontSize: 8, fill: "tomato" },
              maxLabels: { fontSize: 8, fill: "orange" },
            }}
          />
          <VictoryScatter
           labelComponent={
            <VictoryTooltip
              cornerRadius={({ datum }) => datum.x > 6 ? 0 : 20}
              pointerLength={({ datum }) => datum.y > 0 ? 5 : 20}
              flyoutStyle={{
                stroke: ({ datum }) => datum.x === 10
                  ? "tomato"
                  : "black"
              }}
            />
          }

            style={{ data: { fill: "#c43a31" } }}
            size={2}
            data={[
              {
                x: "Lab1",
                min: 11,
                y: 11.96,
                max: 13.05,
                q1: 11.7,
                q3: 12.23,
              },
              {
                x: "Lab2",
                min: 10.5,
                y: 11.92,
                max: 12.95,
                q1: 11.63,
                q3: 12.23,
              },
              {
                x: "Lab3",
                min: 10.42,
                y: 11.84,
                max: 15.73,
                q1: 11.46,
                q3: 12.29,
              },
              {
                x: "Lab4",
                min: 10.26,
                y: 11.63,
                max: 12.97,
                q1: 11.39,
                q3: 11.92,
              },
              {
                x: "Lab5",
                min: 10.42,
                y: 12.04,
                max: 13.52,
                q1: 11.81,
                q3: 12.41,
              },
              {
                x: "Lab6",
                min: 10.3,
                y: 11.6,
                max: 14.2,
                q1: 11.13,
                q3: 12.5,
              },
              {
                x: "Lab7",
                min: 10.6,
                y: 12.24,
                max: 14.46,
                q1: 11.76,
                q3: 12.68,
              },
              {
                x: "Lab8",
                min: 8.04,
                y: 12.28,
                max: 21.82,
                q1: 11.4,
                q3: 13.24,
              },
              {
                x: "Lab9",
                min: 10.05,
                y: 11.76,
                max: 12.75,
                q1: 11.48,
                q3: 12.03,
              },
              {
                x: "Lab10",
                min: 10.55,
                y: 12,
                max: 12.58,
                q1: 11.83,
                q3: 12.14,
              },
              {
                x: "Lab11",
                min: 6.71,
                y: 11.5,
                max: 15.3,
                q1: 11.29,
                q3: 11.8,
              },
              {
                x: "Lab12",
                min: 10.57,
                y: 11.73,
                max: 12.9,
                q1: 11.47,
                q3: 12.06,
              },
              {
                x: "Lab13",
                min: 10.2,
                y: 11.57,
                max: 12.41,
                q1: 11.37,
                q3: 11.78,
              },
              {
                x: "Lab14",
                min: 9.51,
                y: 12.32,
                max: 13.72,
                q1: 11.7,
                q3: 12.78,
              },
            ]}
            // events={[
            //   {
            //     target: "data",
            //     eventHandlers: {
            //       onClick: () => {
            //         return [
            //           {
            //             target: "data",
            //             mutation: (props) => {
            //               const fill = props.style && props.style.fill;
            //               return fill === "black"
            //                 ? null
            //                 : { style: { fill: "black" } };
            //             },
            //           },
            //           {
            //             target: "labels",
            //             mutation: (props) => {
            //               return props.text === "clicked"
            //                 ? null
            //                 : { text: "clicked" };
            //             },
            //           },
            //         ];
            //       },
            //     },
            //   },
            // ]}
          />
          <VictoryLine
            style={{
              data: { strokeDasharray: '2,2', stroke: "#ccc" , strokeWidth: 1, border: "0px dotted #ccc"},
              // parent: { border: "0px dotted #ccc" },
            }}
            data={[
              { x: 0, y: 12.34 },
              { x: 16, y: 12.34 },
            ]}
          />
          <VictoryLine
            style={{
              data: { strokeDasharray: '2,2', stroke: "#ccc" , strokeWidth: 1},
              // parent: { border: "0px dotted #ccc" },
            }}
            data={[
              { x: 0, y: 11.3 },
              { x: 16, y: 11.3 },
            ]}
          />
        </VictoryChart>
      </div>
    );
  }
}

export default BoxPlot1;
