import React, { Component } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DatePicker
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Switch from '@material-ui/core/Switch';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormatAlignLeftIcon from '@material-ui/icons/Clear';
import FormatAlignCenterIcon from '@material-ui/icons/BarChart';
import FormatAlignRightIcon from '@material-ui/icons/VerticalAlignTop';
import { Tooltip } from '@material-ui/core';
class StatisticsFilters extends Component {
    constructor(props) {
        super();
        this.handleChangeDevice = this.handleChangeDevice.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeToDate = this.handleChangeToDate.bind(this);
        this.handleChangeLab = this.handleChangeLab.bind(this);
        this.handleChangeParam = this.handleChangeParam.bind(this);
        this.handleChangeEma12 = this.handleChangeEma12.bind(this);
        this.handleChangeEma12b = this.handleChangeEma12b.bind(this);
        this.handleChangeEma12c = this.handleChangeEma12c.bind(this);
        this.handleChangeYpan = this.handleChangeYpan.bind(this);

        const selectedLab = props.labs[1];
        const selectedDevice = selectedLab && selectedLab.devices ? selectedLab.devices[0] : { id: 0 };
        this.state = {
            val: {
                param: 'FT4',//props.params[0],
                lab: selectedLab ? selectedLab : '0',
                device: selectedDevice,
                // date: props.form.date,
                // toDate: props.form.toDate,
                date: new Date('2015-01-01'),
                toDate: new Date('2015-12-31'),
                ema12: false,
                ema26: false,
                macd: false,
                distribution: '100',
                range: true,
                ypan: true
            }
        }
    }
    // componentDidMount() {
    //     const selectedLab = this.props.labs[1];
    //     const selectedDevice = selectedLab && selectedLab.devices ? selectedLab.devices[0] : { id: 0 };

    //     if (

    //         this.state.form.date != this.props.form.date ||
    //     this.props.form.toDate != this.props.form.toDate)
    //         this.setState({
    //             form: {
    //                 param: this.props.params[0],
    //                 lab: this.selectedLab ? selectedLab : '0',
    //                 device: selectedDevice,
    //                 date: this.props.form.date,
    //                 toDate: this.props.form.toDate,
    //             }
    //         });
    // }

    handleChangeLab(e) {
        const selectedLab = this.props.labs.filter(v => v.id === e.target.value)[0];
        const selectedDevice = selectedLab && selectedLab.devices ? selectedLab.devices[0] : { id: 0 };
        let v = { ...this.state.val, lab: selectedLab, device: selectedDevice };
        this.setState({
            val: v
        });
        this.props.handleChangeForm(v);
    }

    handleChangeDevice(e) {
        const selectedDevice = this.state.val.lab.devices.filter(v => v.id === e.target.value)[0];
        let v = { ...this.state.val, device: selectedDevice }
        this.setState({
            val: v
        });
        this.props.handleChangeForm(v);
    }

    handleChangeDate(date) {
        let v = { ...this.state.val, date: date };
        this.setState({
            val: v
        });
        this.props.handleChangeForm(v);
    }
    handleChangeToDate(date) {
        let v = { ...this.state.val, toDate: date };
        this.setState({
            val: v
        });
        this.props.handleChangeForm(v);
    }
    handleChangeParam(param) {
        let v = { ...this.state.val, param: param.target.value };
        this.setState({
            val: v
        });
        this.props.handleChangeForm(v);
    }
    handleChangeEma12(event) {
        let v = { ...this.state.val, [event.target.name]: event.target.checked };
        this.setState({
            val: v
        });
        this.props.handleChangeForm(v);
    }
    handleChangeEma12b(event, newAlignment) {
        console.log(event.target);
        console.log(newAlignment);
        let v = { ...this.state.val, distribution: newAlignment };
        this.setState({
            val: v
        });
        this.props.handleChangeForm(v);
    }
    handleChangeEma12c(event, newAlignment) {
        console.log(event.target);
        console.log(newAlignment);
        let v = { ...this.state.val, distribution: event.target.value };
        this.setState({
            val: v
        });
        this.props.handleChangeForm(v);
    }
    handleChangeYpan(event) {
        let v = { ...this.state.val, [event.target.name]: event.target.checked };
        this.setState({
            val: v
        });
        this.props.handleChangeForm(v);
    }

    render() {
        const { labs, params, classes } = this.props;
        const selectedLab = this.state.val['lab'] ? this.state.val['lab'] : labs[0];
        return (
            <div>
                <div>
                    <TextField
                        required
                        id={"labid"}
                        value={this.state.val['lab'].id ?? ""}
                        name={"lab"}
                        select
                        className={classes.selectfilter}
                        // label={"Laboratory"}
                        onChange={this.handleChangeLab}
                        InputProps={{ disableUnderline: true }}
                    //  helperText={"Select lab"}
                    >
                        {labs.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    < TextField
                        required
                        id={"device"}
                        name={"device"}
                        // defaultValue={this.state.val['device']}
                        className={classes.selectfilter}
                        value={this.state.val['device'].id ?? ""}
                        InputProps={{ disableUnderline: true }}
                        select
                        // label={"Device"}
                        onChange={this.handleChangeDevice}
                    // helperText={"Select device"}
                    >
                        {selectedLab && selectedLab.devices &&
                            selectedLab.devices.map((option) => (
                                <MenuItem key={selectedLab.id + option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                    </TextField>
                </div>
                <TextField
                    required
                    id={"param"}
                    value={this.state.val['param'] ?? ""}
                    name={"param"}
                    select
                    InputProps={{ disableUnderline: true }}
                    className={classes.selectfilter}
                    onChange={this.handleChangeParam}
                //  helperText={"Select lab"}
                >
                    {params.map((option1) => (
                        <MenuItem key={option1} value={option1}>
                            {option1}
                        </MenuItem>
                    ))}
                </TextField>


                {/* <TextField
                        id="date"
                        label="Birthday"
                        type="date"
                        value={this.state.val['date']}
                        onChange={this.handleChangeDate}
                        format="MM/dd/yyyy"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    /> */}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <span className={classes.datefilter}>
                        <DatePicker
                            required
                            // className={classes.datetime}
                            className={classes.datetime}
                            disableToolbar
                            variant="outlined"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date"
                            autoOk={true}
                            name="date"
                            // label="Start"
                            value={this.state.val['date']}
                            onChange={this.handleChangeDate}
                            // helperText={"Select date"}
                            InputProps={{ disableUnderline: true, className: classes.input }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{ width: 100 }}
                        />
                    </span>
                    <span className={classes.datefilter}>
                        <DatePicker
                            required
                            className={classes.datetime}
                            disableToolbar
                            variant="outlined"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date"
                            autoOk={true}
                            name="date"
                            // label="End"
                            value={this.state.val['toDate']}
                            onChange={this.handleChangeToDate}
                            // helperText={"Select date"}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            InputProps={{ disableUnderline: true, className: classes.input }}
                            style={{ width: 100 }}
                        />

                    </span>
                </MuiPickersUtilsProvider>


                <FormControlLabel className={classes.checkboxfilter}
                    control={
                        <Checkbox
                            checked={this.state.val['ema12']}
                            value={this.state.val['ema12']}
                            onChange={this.handleChangeEma12}
                            name="ema12"
                            color="primary"
                        />
                    }
                    label="7-day avg."
                />
                {/* <ToggleButton
                    value="check"
                    selected={this.state.val['ema12']}
                    // onChange={() => {
                    //     onChange={{this.handleChangeEma12}}
                    // }}
                >
                    7d MA
                </ToggleButton> */}
                <FormControlLabel className={classes.checkboxfilter}
                    control={
                        <Checkbox
                            checked={this.state.val['ema26']}
                            value={this.state.val['ema26']}
                            onChange={this.handleChangeEma12}
                            name="ema26"
                            color="primary"
                        />
                    }
                    label="30-day avg."
                />
                {/* < TextField
                    required
                    id={"ema26"}
                    name={"ema26"}
                    // defaultValue={this.state.val['device']}
                    className={classes.selectfilter}
                    value={this.state.val['ema26']}
                    InputProps={{ disableUnderline: true }}
                    select
                    // label={"Device"}
                    onChange={this.handleChangeEma12}
                // helperText={"Select device"}
                >
                    <MenuItem key={1} value={false}>
                        None
                    </MenuItem>
                    <MenuItem key={7} value={true}>
                        7-day m.avg.
                    </MenuItem>
                    <MenuItem key={30} value={30}>
                        30-day m.avg.
                    </MenuItem>
                </TextField> */}

                {/* <FormControlLabel className={classes.checkboxfilter}
                    control={
                        <Checkbox
                            checked={this.state.val['macd']}
                            value={this.state.val['macd']}
                            onChange={this.handleChangeEma12}
                            name="macd"
                            color="primary"
                        />
                    }
                    label="macd"
                /> */}
                {/* <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.val['distribution']}
                                value={this.state.val['distribution']}
                                onChange={this.handleChangeEma12}
                                name="distribution"
                                color="primary"
                            />
                        }
                        label="Hypo&hyper"
                    /> */}

                <TextField
                    required
                    id={"distribution"}
                    value={this.state.val['distribution']}
                    name={"distribution"}
                    select
                    className={classes.selectfilter}
                    // label={"Laboratory"}
                    onChange={this.handleChangeEma12c}
                    InputProps={{ disableUnderline: true }}
                //  helperText={"Select lab"}
                >
                    <MenuItem key={1} value={false}>
                        None
                    </MenuItem>
                    <MenuItem key={2} value={true}>
                        Hypo&hyper
                    </MenuItem>
                    <MenuItem key={3} value={'100'}>
                        Hypo&hyper %
                    </MenuItem>
                </TextField>

                {/* <ToggleButtonGroup
                    size="small"
                    value={this.state.val['distribution']}
                    exclusive
                    className={classes.buttongroupfilter}
                    onChange={this.handleChangeEma12b}
                    aria-label="text alignment"
                    name="distribution"
                >
                    <ToggleButton value={false} aria-label="left aligned">
                        <Tooltip placement="bottom" title='None' >
                            <FormatAlignLeftIcon fontSize="small" />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value={true} aria-label="centered">
                        <Tooltip placement="bottom" title='Hypo&hyper' >
                            <FormatAlignCenterIcon fontSize="small" />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value='100' aria-label="right aligned">
                        <Tooltip placement="bottom" title='Hypo&hyper %' >
                            <FormatAlignRightIcon fontSize="small" />
                        </Tooltip>
                    </ToggleButton>
                </ToggleButtonGroup> */}

                {/* <FormControlLabel className={classes.checkboxfilter}
                    control={
                        <Checkbox
                            checked={this.state.val['range']}
                            value={this.state.val['range']}
                            onChange={this.handleChangeEma12}
                            name="range"
                            color="primary"
                        />
                    }
                    label="Range"
                /> */}

                {/* <FormControlLabel className={classes.switchfilter}
                    control={
                        <Switch
                            checked={this.state.val['yPan']}
                            value={this.state.val['yPan']}
                            onChange={this.handleChangeYpan}
                            name="ypan"
                            color="primary"
                        />
                    }
                    label="Align"
                /> */}
                
            </div>
        );
    }
}

export default StatisticsFilters;