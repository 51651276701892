import React, { Component } from "react";
import { VictoryBoxPlot } from "victory";
import {
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryZoomContainer,
  VictoryScatter,
  VictoryLine,
  VictoryBrushContainer,
  VictoryAxis,
} from "victory";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import * as V from "victory";
import { IconButton, MenuItem, TextField } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import AnalytesParameterButton from "../analytes/AnalytesParameterButton";
import Button from "@material-ui/core/Button";
import { FilterList, Settings, TextRotateUp, TextRotationDown, TextRotationNone } from "@material-ui/icons";
import { HorizontalSplit, VerticalAlignTop, ViewAgenda, ViewColumn } from "@material-ui/icons";
import Menu from '@material-ui/core/Menu';
import { ListItemIcon, ListItemText } from "@material-ui/core";

class BoxPlot2 extends Component {
  constructor(props) {
    super();
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  state = {
    param: "FT4",
    date: "1/1/2022",
    labs: ["Lab 1", "Lab 2"],
    dataa: ["Median", "Hypo", "Hyper"],
    view: ["Long-term median", "1.st, 3.de quartile", "Min, max"],
    params: [
      "Albumin",
      "Alkaline Phosphatase",
      "ALT - GPT",
      "AST - GOT",
      "Calcium",
      "Chloride",
      "Creatinine",
      "FT4",
      "GGT",
      "Glucose",
      "Inorganic phosphor",
      "LDH",
      "Magnesium",
      "Potassium",
      "Sodium",
      "total-Bilirubin",
      "total-Cholesterol",
      "total-Protein",
      "TSH",
      "Urea",
      "Uric acid",
    ],
    anchorEl: null,
    layout: 4,
  };


  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };


  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleVClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ layout: 4 });
  };
  handleHClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ layout: 12 });
  };

  render() {
    const { classes, labs } = this.props;
    const { anchorEl } = this.state;
    return (
      <div>
        <TextField
          // required
          id={"param"}
          value={this.state.param ?? ""}
          name={"param"}
          select
          label={"Analyte"}
          //  InputProps={{ disableUnderline: true }}
          className={classes.selectfilter2}
          // onChange={this.handleChangeParam}
          //  helperText={"Select lab"}
        >
          {this.state.params.map((option1) => (
            <MenuItem key={option1} value={option1}>
              {option1}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          // required
          id={"labid"}
          value={this.state.labs ?? ["Lab 1"]}
          name={"lab"}
          select
          className={classes.selectfilter2}
          label={"Laboratories"}
          SelectProps={{
             multiple: true,
             value: this.state.labs,
          }}
          // onChange={this.handleChangeLab}
          // InputProps={{ disableUnderline: true }}
          //  helperText={"Select lab"}
        >
          {this.state.labs.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            // required
            // className={classes.datetime}
            className={classes.selectfilter3}
            disableToolbar
            variant="inline"
            format="MM/yyyy"
            margin="normal"
            id="date"
            autoOk={true}
            name="date"
            label="Month"
            value={this.state.date}
            // onChange={this.handleChangeDate}
            // helperText={"Select date"}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
        <TextField
          // required
          id={"data"}
          value={ "Median"}
          name={"data"}
          select
          label={"Measurement"}
          //  InputProps={{ disableUnderline: true }}
          className={classes.selectfilter2}
          // onChange={this.handleChangeParam}
          //  helperText={"Select lab"}
        >
          {this.state.dataa.map((option1) => (
            <MenuItem key={option1} value={option1}>
              {option1}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          // required
          id={"viewid"}
          value={this.state.view }
          name={"viewid"}
          select
          className={classes.selectfilter2}
          label={"Optional"}
          SelectProps={{
             multiple: true,
             value: this.state.view,
          }}
          // onChange={this.handleChangeLab}
          // InputProps={{ disableUnderline: true }}
          //  helperText={"Select lab"}
        >
          {this.state.view.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <IconButton
          color="primary"
          className={classes.button2}
          aria-label="upload picture"
          component="span"
        >
          <FilterList />
        </IconButton>
        <IconButton
          aria-label="More"
          aria-owns={anchorEl ? 'long-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <Settings />
        </IconButton>
          <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          // PaperProps={{
          //   style: {
          //     maxHeight: ITEM_HEIGHT * 4.5,
          //     width: 200,
          //   },
          // }}
        >
          <MenuItem
            onClick={this.handleVClose}
            className={classes.menuItem}
          >
            <ListItemIcon className={classes.icon}>
              <TextRotationDown />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.primary,
              }}
              inset
              primary={'Vertical'}
            />
          </MenuItem>
          <MenuItem
            onClick={this.handleHClose}
            className={classes.menuItem}
          >
            <ListItemIcon className={classes.icon}>
              <TextRotationNone />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.primary,
              }}
              inset
              primary={'Horizontal'}
            />
          </MenuItem>
        </Menu>
        <Button
          // variant={labDeviceParameter && labDeviceParameter.includes(labDevice + measure) ? "contained" : "outlined"}
          color="primary"
          variant="outlined"
          // size="small"
          className={classes.button2}
          //  endIcon={<Icon>send</Icon>}
          // onClick={() => this.handleClick(measure)}
        >
          {" "}
          Filter
        </Button>
        <VictoryChart
          domainPadding={20}
          // containerComponent={
          //   <VictoryVoronoiContainer
          //     mouseFollowTooltips
          //     voronoiDimension="x"
          //     labels={({ datum }) => `y: ${datum.median}`}
          //     labelComponent={
          //       <VictoryTooltip
          //         cornerRadius={0}
          //         flyoutStyle={{ fill: "white" }}
          //       />
          //     }
          //   />
          // }
        >
          <VictoryLabel text="FT4" x={225} y={30} textAnchor="middle"/>
          <VictoryAxis
            crossAxis
            label="Instruments"
            style={{
              axisLabel: { fontSize: 8, padding: 35 },
              tickLabels: { fontSize: 6, padding: 5 },
            }}
          />
          <VictoryAxis
            dependentAxis
            crossAxis
           label="pmol/L"
            style={{
              axisLabel: { fontSize: 8, padding: 25 },
              tickLabels: { fontSize: 6, padding: 5 },
            }}
          />
          
          <VictoryBoxPlot 
            horizontal={this.state.layout === 4} 
            labelComponent={<VictoryTooltip />}
            // minLabels
            // maxLabels
            boxWidth={10}
            whiskerWidth={5}
            data={[
              //   { x: "Alinity Lab 1", y: [1, 2, 3, 5] },
              //   { x: "Alinity Lab 2", y: [3, 2, 8, 10] },
              //   { x: "Alinity Lab 3", y: [2, 8, 6, 5] },
              //   { x: "Alinity Lab 4", y: [1, 3, 2, 9] },
              {
                x: "LCBN11",
                min: 11.21,
                median: 11.5,
                max: 12.3,
                q1: 11.29,
                q3: 11.8,
              },
              {
                x: "LCBN13",
                min: 11.2,
                median: 11.57,
                max: 12.41,
                q1: 11.37,
                q3: 11.78,
              },

              {
                x: "LCBN6",
                min: 11.0,
                median: 11.6,
                max: 12.7,
                q1: 11.13,
                q3: 12.5,
              },
              {
                x: "LCBN4",
                min: 11.26,
                median: 11.63,
                max: 12.97,
                q1: 11.39,
                q3: 11.92,
              },
              {
                x: "LCBN12",
                min: 11.27,
                median: 11.73,
                max: 12.9,
                q1: 11.47,
                q3: 12.06,
              },
              {
                x: "LCBN9",
                min: 11.05,
                median: 11.76,
                max: 12.75,
                q1: 11.48,
                q3: 12.03,
              },
              {
                x: "LCBN3",
                min: 11.42,
                median: 11.84,
                max: 12.73,
                q1: 11.46,
                q3: 12.29,
              },
              {
                x: "Median",
                min: 11,
                median: 11.84,
                max: 13.82,
                q1: 11.26,
                q3: 12.49,
              },
              {
                x: "LCBN2",
                min: 11.5,
                median: 11.92,
                max: 12.95,
                q1: 11.63,
                q3: 12.23,
              },
              {
                x: "LCBN1",
                min: 11,
                median: 11.96,
                max: 13.05,
                q1: 11.7,
                q3: 12.23,
              },
              {
                x: "LCBN10",
                min: 11.55,
                median: 12,
                max: 12.20,
                q1: 11.83,
                q3: 12.14,
              },
              {
                x: "LCBN5",
                min: 11.6,
                median: 12.04,
                max: 13.52,
                q1: 11.81,
                q3: 12.41,
              },
              {
                x: "LCBN7",
                min: 11.6,
                median: 12.24,
                max: 12.88,
                q1: 11.76,
                q3: 12.68,
              },
              {
                x: "LCBN8",
                min: 11.01,
                median: 12.28,
                max: 13.82,
                q1: 11.4,
                q3: 13.24,
              },
              {
                x: "LCBN14",
                min: 11.51,
                median: 12.52,
                max: 13.72,
                q1: 11.7,
                q3: 12.78,
              },
            ]}
            style={{
              // min: { stroke: "tomato" },
              // max: { stroke: "orange" },
              min: { stroke: ({ datum }) => datum.min < 11.3
              ? "tomato"
              : "#C5E1A5" },
              max: { stroke: ({ datum }) => datum.max > 12.34
              ? "orange"
              : "#C5E1A5" },
              q1: { fill: ({ datum }) => datum.q1 < 11.3
              ? "tomato"
              : "#C5E1A5" },
              q3: { fill: ({ datum }) => datum.q3 > 12.34
              ? "orange"
              : "#C5E1A5" },
              median: { stroke: "white", strokeWidth: 2 },
              minLabels: { fontSize: 6, fill: "tomato" },
              maxLabels: { fontSize: 6, fill: "orange" },
            }}
          />
          <VictoryScatter
            labelComponent={
              <VictoryTooltip
                cornerRadius={({ datum }) => (datum.x > 6 ? 0 : 20)}
                pointerLength={({ datum }) => (datum.y > 0 ? 5 : 20)}
                flyoutStyle={{
                  stroke: ({ datum }) =>
                    datum.x === "Median" ? datum.y < 12.34 && datum.y > 11.3
                    ? "#C5E1A5"
                    : "orange" 
                    
                    : "black",
                }}
              />
            }
            style={{
              data: {
                fill: ({ datum }) =>
                  datum.x === "Median" ? "black" : 
                  datum.y < 12.34 && datum.y > 11.3
                  ? "#039BE5"
                    : "#c43a31" 
              },
            }}
            size={2}
            data={[
              {
                x: "LCBN11",
                min: 6.71,
                y: 11.5,
                max: 15.3,
                q1: 11.29,
                q3: 11.8,
              },
              {
                x: "LCBN13",
                min: 10.2,
                y: 11.57,
                max: 12.41,
                q1: 11.37,
                q3: 11.78,
              },

              {
                x: "LCBN6",
                min: 10.3,
                y: 11.6,
                max: 14.2,
                q1: 11.13,
                q3: 12.5,
              },
              {
                x: "LCBN4",
                min: 10.26,
                y: 11.63,
                max: 12.97,
                q1: 11.39,
                q3: 11.92,
              },
              {
                x: "LCBN12",
                min: 10.57,
                y: 11.73,
                max: 12.9,
                q1: 11.47,
                q3: 12.06,
              },
              {
                x: "LCBN9",
                min: 10.05,
                y: 11.76,
                max: 12.75,
                q1: 11.48,
                q3: 12.03,
              },
              {
                x: "LCBN3",
                min: 10.42,
                y: 11.84,
                max: 15.73,
                q1: 11.46,
                q3: 12.29,
              },
              {
                x: "LCBN2",
                min: 10.5,
                y: 11.92,
                max: 12.95,
                q1: 11.63,
                q3: 12.23,
              },
              {
                x: "LCBN1",
                min: 11,
                y: 11.96,
                max: 13.05,
                q1: 11.7,
                q3: 12.23,
              },
              {
                x: "LCBN10",
                min: 10.55,
                y: 12,
                max: 12.58,
                q1: 11.83,
                q3: 12.14,
              },
              {
                x: "LCBN5",
                min: 10.42,
                y: 12.04,
                max: 13.52,
                q1: 11.81,
                q3: 12.41,
              },
              {
                x: "LCBN7",
                min: 10.6,
                y: 12.24,
                max: 14.46,
                q1: 11.76,
                q3: 12.68,
              },
              {
                x: "LCBN8",
                min: 8.04,
                y: 12.28,
                max: 21.82,
                q1: 11.4,
                q3: 13.24,
              },
              {
                x: "LCBN14",
                min: 9.51,
                y: 12.52,
                max: 13.72,
                q1: 11.7,
                q3: 12.78,
              },
              {
                x: "Median",
                min: 11,
                y: 11.84,
                max: 13.82,
                q1: 11.46,
                q3: 12.29,
              },
            ]}
            // events={[
            //   {
            //     target: "data",
            //     eventHandlers: {
            //       onClick: () => {
            //         return [
            //           {
            //             target: "data",
            //             mutation: (props) => {
            //               const fill = props.style && props.style.fill;
            //               return fill === "black"
            //                 ? null
            //                 : { style: { fill: "black" } };
            //             },
            //           },
            //           {
            //             target: "labels",
            //             mutation: (props) => {
            //               return props.text === "clicked"
            //                 ? null
            //                 : { text: "clicked" };
            //             },
            //           },
            //         ];
            //       },
            //     },
            //   },
            // ]}
          />
          <VictoryLine
            style={{
              data: { strokeDasharray: '2,2', stroke: "#ccc" , strokeWidth: 1, border: "0px dotted #ccc"},
              // parent: { border: "0px dotted #ccc" },
            }}
            data={[
              { x: 0, y: 12.34 },
              { x: 16, y: 12.34 },
            ]}
          />
          <VictoryLine
            style={{
              data: { strokeDasharray: '2,2', stroke: "#ccc" , strokeWidth: 1},
              // parent: { border: "0px dotted #ccc" },
            }}
            data={[
              { x: 0, y: 11.3 },
              { x: 16, y: 11.3 },
            ]}
          />
        </VictoryChart>
      </div>
    );
  }
}

export default BoxPlot2;
